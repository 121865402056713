import axiosInstance from "../config/axios.config";
import * as env from "../config/env"
const headers = {};

const baseUrl = `${env.url}/api/promos`

export function readAll() {
    const config = {
        method: "GET",
        headers
    };
    return axiosInstance
        .get(baseUrl, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function create(promoData) {
    const config = {
      method: "POST",
      headers,
      data: promoData
    };
  
    return axiosInstance(baseUrl, config)
      .then(responseSuccessHandler)
      .catch(responseErrorHandler);
}

export function deleteCodes(itemsToDelete){
    const config = {
        method: "DELETE",
        headers,
        data: itemsToDelete,
    };
    return axiosInstance(baseUrl, config)
        .then(responseErrorHandler)
        .catch(responseErrorHandler)
}

const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = error => {
    console.log(error);
    return Promise.reject(error);
};