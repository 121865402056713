import axios from "axios";
import axiosInstance from "../config/axios.config";

const headers = {};

// const baseUrl = `http://0aeb4394.ngrok.io/api/products`;
const baseUrl = `http://18.144.33.237:8080/api/terms`;
// const baseUrl = `http://localhost:8081/api/terms`;


export function readByUserId(id) {
  const config = {
      method: "GET",
      headers
  };
  return axiosInstance(baseUrl + "/" + id, config)
      .then(responseSuccessHandler)
      .catch(responseErrorHandler);
}

export function create(productData) {
  const config = {
    method: "POST",
    headers,
    data: productData
  };

  return axios(baseUrl, config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler);
}

export function readAll() {
  const config = {
    method: "GET",
    headers
  };

  return axios(baseUrl, config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler);
}

export function update(productData) {
  const config = {
    method: "PUT",
    headers,
    data: productData
  };

  return axios(`${baseUrl}/${productData.id}`, config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler);
}

export function del(id) {
  const config = {
    method: "DELETE",
    headers
  };

  return axios(`${baseUrl}/${id}`, config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
  return response.data;
};

const responseErrorHandler = error => {
  console.log(error);
  return Promise.reject(error);
};