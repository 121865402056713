import React from "react";
import {  FcElectricity, FcPositiveDynamic, FcRedo, FcSalesPerformance,  FcWorkflow, FcButtingIn } from "react-icons/fc";
//import { BsArrowCounterclockwise } from "react-icons/bs";
//import { IconContext } from "react-icons";
import * as statsService from "../services/stats.service";
import * as orderService from "../services/orders.service";
//import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const moment = require("moment");


class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [],
      allOrders: null,
      ordersToday: [],
      gotOrders: false,
      numOrdersToday: null,
      totalOrders: null,
      orderChangeText: "",
      changingOrders: null,
      numOrdersMonth: null,
      numOrdersWeek: null,
      numOrdersYear: null,
      numOrdersQuarter: null,

      allRefunds: null,
      totalRefunds: null,
      gotRefunds: false,
      refundsToday: [],
      numRefundsToday: null,
      refundChangeText: "",
      changingRefunds: null,
      numRefundsMonth: null,
      numRefundsWeek: null,
      numRefundsYear: null,
      numRefundsQuarter: null,

      gotOrderCycle: false,
      orderCycle: null,

      numLoggedIn: null,
      gotLoggedIn: null,
    };

    this.getUsers = this.getUsers.bind(this);
    this.orderTimeChange = this.orderTimeChange.bind(this);
    this.refundTimeChange = this.refundTimeChange.bind(this);
  }

  componentDidMount() {
    orderService.allOrderData().then(data1 => {
      let arr
      arr = data1.items.filter(function (item) {
        const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
        const time = moment(newDate).unix()
        const startOfDay = moment().startOf('day').unix()
        return time > startOfDay
      });

      arr = arr.sort(function (a, b) {
        return parseInt(b.id) - parseInt(a.id)
      });

      this.setState({ 
        allOrders: data1,
        gotOrders: true, 
        ordersToday: arr,
        numOrdersToday: arr.length,
        totalOrders: data1.items.length,
        orderChangeText: "Since this Morning",
        changingOrders: arr.length
      });

    }).catch(err => {
      console.log(`Error Getting  Order Data: ${err}`);
    });

    statsService.getRefunds().then(response => {
      let myArr;
      myArr = response.items.filter(function (item) {
        const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
        const time = moment(newDate).unix()
        const startOfDay = moment().startOf('day').unix()
        return time > startOfDay
      });

      this.setState({ 
        gotRefunds: true, 
        allRefunds: response,
        numRefundsToday: myArr.length,
        totalRefunds: response.items.length,
        refundChangeText: "Since This Morniing"
      });
    }).catch(err => {
      console.log(`Error Getting Refund Data: ${err}`);
    });

    statsService.getCycleTime().then(response => {
      let  myOrderCycle =  response.items[0]['avgOrderCycle'];
      this.setState({ 
        gotOrderCycle: true,
        orderCycle: myOrderCycle
      });
    }).catch(err => {
      console.log(`Error Getting Order Cycle Data: ${err}`);
    });

    statsService.getUsersLoggedIn().then(response => {
      let users = response;
      let numUsers = users.length;
      this.setState({
        gotLoggedIn: true,
        numLoggedIn: numUsers
      });
    }).catch(err => {
      console.log(`Error Getting Users Logged In: ${err}`);
    });
    
  }

  getUsers = () => {
    statsService.getUsersLoggedIn().then(response => {
      let users = response;
      let numUsers = users.length;
      this.setState({
        numLoggedIn: numUsers
      });
      return numUsers;
    }).catch(err => {
      console.log(`Error Getting Users Logged In: ${err}`);
    });
  }

  orderTimeChange = e => {
    let timeFrame = e.target.value;

    if (timeFrame === "DAY") {
      this.setState({ 
        orderChangeText: "Since this Morning",
        changingOrders: this.state.numOrdersToday
      });
    } else if (timeFrame === "WEEK") {
      if (this.state.numOrdersWeek != null) {
        this.setState({ 
          orderChangeText: "This Week",
          changingOrders: this.state.numOrdersWeek
        });
      } else {
        let arr;
        arr = this.state.allOrders.items.filter(function (item) {
          const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
          const time = moment(newDate).unix()
          const startOfWeek = moment().startOf('week').unix()
          return time > startOfWeek
        });
        this.setState({ 
          orderChangeText: "This Week",
          numOrdersWeek: arr.length,
          changingOrders: arr.length
        });
      }

    } else if (timeFrame === "MONTH") {
      if (this.state.numOrdersMonth != null) {
        this.setState({ 
          orderChangeText: "This Month",
          changingOrders: this.state.numOrdersMonth
        });
      } else {
        let arr;
        arr = this.state.allOrders.items.filter(function (item) {
          const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
          const time = moment(newDate).unix()
          const startOfMonth = moment().startOf('month').unix()
          return time > startOfMonth
        });
        this.setState({ 
          orderChangeText: "This Month",
          numOrdersMonth: arr.length,
          changingOrders: arr.length
        });
      }
    } else if (timeFrame === "YEAR") {
      if (this.state.numOrdersYear != null) {
        this.setState({ 
          orderChangeText: "This Year",
          changingOrders: this.state.numOrdersYear
        });
      } else {
        let arr;
        arr = this.state.allOrders.items.filter(function (item) {
          const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
          const time = moment(newDate).unix()
          const startOfYear = moment().startOf('year').unix()
          return time > startOfYear
        });
        this.setState({ 
          orderChangeText: "This Year",
          numOrdersYear: arr.length,
          changingOrders: arr.length
        });
      }
    } else {
      if (this.state.numOrdersQuarter != null) {
        this.setState({ 
          orderChangeText: "This Quarter",
          changingOrders: this.state.numOrdersQuarter
        });
      } else {
        let arr;
        arr = this.state.allOrders.items.filter(function (item) {
          const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
          const time = moment(newDate).unix()
          const startOfQuarter = moment().startOf('quarter').unix()
          return time > startOfQuarter
        });
        this.setState({ 
          orderChangeText: "This Quarter",
          numOrdersQuarter: arr.length,
          changingOrders: arr.length
        });
      }
    }

  }

  refundTimeChange = e => {
    let timeFrame = e.target.value;

    if (timeFrame === "DAY") {
      this.setState({ 
        refundChangeText: "Since this Morning",
        changingRefunds: this.state.numRefundsToday
      });
    } else if (timeFrame === "WEEK") {
      if (this.state.numRefundsWeek != null) {
        this.setState({ 
          refundChangeText: "This Week",
          changingRefunds: this.state.numRefundsWeek
        });
      } else {
        let arr;
        arr = this.state.allRefunds.items.filter(function (item) {
          const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
          const time = moment(newDate).unix()
          const startOfWeek = moment().startOf('week').unix()
          return time > startOfWeek
        });
        this.setState({ 
          refundChangeText: "This Week",
          numRefundsWeek: arr.length,
          changingRefunds: arr.length
        });
      }

    } else if (timeFrame === "MONTH") {
      if (this.state.numRefundsMonth != null) {
        this.setState({ 
          refundChangeText: "This Month",
          changingRefunds: this.state.numRefundsMonth
        });
      } else {
        let arr;
        arr = this.state.allRefunds.items.filter(function (item) {
          const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
          const time = moment(newDate).unix()
          const startOfMonth = moment().startOf('month').unix()
          return time > startOfMonth
        });
        this.setState({ 
          refundChangeText: "This Month",
          numRefundsMonth: arr.length,
          changingRefunds: arr.length
        });
      }
    } else if (timeFrame === "YEAR") {
      if (this.state.numRefundsYear != null) {
        this.setState({ 
          refundChangeText: "This Year",
          changingRefunds: this.state.numRefundsYear
        });
      } else {
        let arr;
        arr = this.state.allRefunds.items.filter(function (item) {
          const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
          const time = moment(newDate).unix()
          const startOfYear = moment().startOf('year').unix()
          return time > startOfYear
        });
        this.setState({ 
          refundChangeText: "This Year",
          numRefundsYear: arr.length,
          changingRefunds: arr.length
        });
      }
    } else {
      if (this.state.numRefundsQuarter != null) {
        this.setState({ 
          refundChangeText: "This Quarter",
          changingRefunds: this.state.numRefundsQuarter
        });
      } else {
        let arr;
        arr = this.state.allRefunds.items.filter(function (item) {
          const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
          const time = moment(newDate).unix()
          const startOfQuarter = moment().startOf('quarter').unix()
          return time > startOfQuarter
        });
        this.setState({ 
          refundChangeText: "This Quarter",
          numRefundsQuarter: arr.length,
          changingRefunds: arr.length
        });
      }
    }

  }

  render () {
    return (
      <React.Fragment>
        <div className="main-content">
          <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
            <div className="container-fluid">
              <h2 className="mb-5 text-black">Printverse Stats</h2>
              
              <div className="header-body">
                <div className="row align-items-lg-center align-items-md-center">

                  <div className="col-sm-6 col-xl-3 col-md-3 col-lg-3">
                    <div className="card mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-title text-uppercase text-muted mb-4">Orders</h5>
                            <span className="h2 font-weight-bold mb-0 verrtical-align-bottom">{this.state.changingOrders}</span>
                          </div>
                          <div className="col-md-4 col-lg-4 text-right">
                            <FcPositiveDynamic className="rounded-circle shadow" size={40}></FcPositiveDynamic>
                          </div>
                          
                        </div>

                        <div className="row">
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-text text-uppercase text-muted mb-4">{this.state.orderChangeText}</h5>
                          </div>
                          <div className="col-md-4 col-lg-4 text-right">
                            <div className="input-group mx-0 mx-md-3">
                              <select name="orderTimeFrame" size="1" title="OrderTimeFrame" onChange={this.orderTimeChange} className="custom-select form-select">
                                <option value="DAY">Day</option>
                                <option value="WEEK">Week</option>
                                <option value="MONTH">Month</option>
                                <option value="YEAR">Year</option>
                                <option value="QUARTER">Quarter</option>
                              </select>
                            </div>
                          </div>
                        </div>  
                        
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6  col-xl-3 col-md-3 col-lg-3">
                    <div className="card mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-title text-uppercase text-muted mb-4">Total Orders</h5>
                            <span className="h2 font-weight-bold mb-0 verrtical-align-bottom">{this.state.totalOrders}</span>
                          </div>
                          <div className="col-md-4 col-lg-4 text-right">
                            <FcSalesPerformance className="rounded-circle shadow" size={40}></FcSalesPerformance>
                          </div>
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-text text-uppercase text-muted mb-4">Since All Time</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6  col-xl-3 col-md-3 col-lg-3">
                    <div className="card mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-title text-uppercase text-muted mb-4">Refunds</h5>
                            <span className="h2 font-weight-bold mb-0 verrtical-align-bottom">{this.state.numRefundsToday}</span>
                          </div>
                          <div className="col-md-4 col-lg-4 text-right">
                            <FcElectricity className="rounded-circle shadow" size={40}></FcElectricity>
                          </div>
                          {/* <div className="col-md-8  col-lg-8">
                            <h5 className="card-text text-uppercase text-muted mb-4">Since This Morning</h5>
                          </div> */}
                        </div>

                        <div className="row">
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-text text-uppercase text-muted mb-4">{this.state.refundChangeText}</h5>
                          </div>
                          <div className="col-md-4 col-lg-4 text-right">
                            <div className="input-group mx-0 mx-md-3">
                              <select name="refundTimeFrame" size="1" title="RefundTimeFrame" onChange={this.refundTimeChange} className="custom-select form-select">
                                <option value="DAY">Day</option>
                                <option value="WEEK">Week</option>
                                <option value="MONTH">Month</option>
                                <option value="YEAR">Year</option>
                                <option value="QUARTER">Quarter</option>
                              </select>
                            </div>
                          </div>
                        </div>  

                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6  col-xl-3 col-md-3 col-lg-3">
                    <div className="card mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-title text-uppercase text-muted mb-4">Total Refunds</h5>
                            <span className="h2 font-weight-bold mb-0 verrtical-align-bottom">{this.state.totalRefunds}</span>
                          </div>
                          <div className="col-md-4 col-lg-4 text-right">
                            <FcRedo className="rounded-circle shadow" size={40}></FcRedo>
                          </div>
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-text text-uppercase text-muted mb-4">Since All Time</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6  col-xl-3 col-md-3 col-lg-3">
                    <div className="card mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-title text-uppercase text-muted mb-4">Avg Order Cycle (Hours)</h5>
                            <span className="h2 font-weight-bold mb-0 verrtical-align-bottom">{this.state.orderCycle}</span>
                          </div>
                          <div className="col-md-4 col-lg-4 text-right">
                            <FcWorkflow className="rounded-circle shadow" size={40}></FcWorkflow>
                          </div>
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-text text-uppercase text-muted mb-4">Since All Time</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6  col-xl-3 col-md-3 col-lg-3">
                    <div className="card mb-4 mb-xl-0">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-title text-uppercase text-muted mb-4">Users (Real Time)</h5>
                            <span className="h2 font-weight-bold mb-0 verrtical-align-bottom">{this.state.numLoggedIn}</span>
                          </div>
                          <div className="col-md-4 col-lg-4 text-right">
                            <FcButtingIn className="rounded-circle shadow" size={40}></FcButtingIn>
                          </div>
                          <div className="col-md-8 col-lg-8">
                            <h5 className="card-text text-uppercase text-muted mb-4">Right Now</h5>
                          </div>
                          <div className="col-md-4 col-lg-4 text-right">
                            <div className="input-group mx-0 mx-md-3">
                              <button className="btn form-btn" onClick={this.getUsers}>Refresh</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Stats;