import axiosInstance from "../config/axios.config";
import axios from "axios";
import * as env from "../config/env"
const headers = {};

const baseUrl = `${env.url}/api/order_data`
const railsUrl = `${env.railsUrl}`

export function sendOrderData(companyId, orderData, po) {
  const config = {
  method: "POST",
  headers,
  data:  {
   "order_data": JSON.stringify(orderData),
   "company_id": companyId,
   "po": po
   }
  }
  //return axios(`${railsUrl}/order_data`, config).then(responseSuccessHandler).catch(responseErrorHandler)
  return axios(`${baseUrl}/order_data`, config).then(responseSuccessHandler).catch(responseErrorHandler)
}

export function getOrderData(po) {
  const config = {
   method: "GET",
   headers,
  };
  return axios(`${baseUrl}/json/${po}`, config).then(responseSuccessHandler).catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
  return response.data;
};

const responseErrorHandler = error => { 
  console.log(error);
  return Promise.reject(error);
};