import axiosInstance from "../config/axios.config";

import * as env from "../config/env"
const headers = {};

const baseUrl = `${env.url}/api/tokens`

export function getTokenByOpUserId(id) {
    const config = {
        method: "GET",
        headers
    };
    return axiosInstance(`${baseUrl}/${id}`, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = error => {
    console.log(error);
    return Promise.reject(error);
};