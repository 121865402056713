import React from "react";
import * as promoService from "../services/promos.service";
import PromoCodesForm from "./PromoCodesForm";
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify';

class Promos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            updated: false,
            itemOffset: 0,
            setItemOffset: 0,
        };

        this.onSelect = this.onSelect.bind(this);
        this.onSave = this.onSave.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.deleteCode = this.deleteCode.bind(this);
    }

    componentDidMount() {
        promoService.readAll().then(data => {
            // let arr = data.items.sort(function (a, b) {
            //     return parseInt(b.id) - parseInt(a.id)
            // })
            this.setState({ data: data.items })
        })
    }
    onSelect(item, event) {
        event.preventDefault();
        this.setState({
            formData: item
        });
    }
    onCancel(){
        this.setState({formData:null})
    }

    onSave(data) {
        // let update = data == 201 ? true: false
        // this.setState({
        //     updated: update
        // })
        promoService.readAll().then(data => {
            // let arr = data.items.sort(function (a, b) {
            //     return parseInt(b.id) - parseInt(a.id)
            // })   
            this.setState({ data: data.items })
        })
    }

    deleteCode(ids){
        // const currentData = this.state.data;
        // this.setState({data: currentData.filter(promo => promo.id != promoId)})
        promoService.deleteCodes(ids)
        .then((data) => {
            this.setState(data.items.filter((item) => item.id !== ids))
            toast.info('Expired/used promos deleted', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            .catch(err => console.log("Error: " + JSON.stringify(err)))
        })
        .catch(err => {
            console.log("err: " + JSON.stringify(err));
            toast.error('Error deleting promos', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    )}
    
    render() {
        const data = this.state.data ? (
            this.state.data.map(dat => (
                <tr className="notFirst" key={dat.id} onClick={this.onSelect.bind(this, dat)}>
                    <td>{`${dat.id}`}</td>
                    <td>{`${dat.code}`}</td>
                    <td>{`${dat.discount}%`}</td>
                    <td>{`${new Date(dat.created_at).toLocaleDateString("en-US")} ${new Date(dat.created_at).toLocaleTimeString("en-US")}`}</td>
                    <td>{`${new Date(dat.expires_at).toLocaleDateString("en-US")} ${new Date(dat.expires_at).toLocaleTimeString("en-US")} `}</td>
                    <td>{`${dat.active == 1 ? "Active" : "Expired/Used"}`}</td>
                    <td>{`${dat.uses}`}</td>
                </tr >
            ))

        ) : (<React.Fragment />)

        let panelBody = {
            overflow: 'auto'
        }

        let panelHead = {
            marginLeft: '3em'
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xs-6">
                        <PromoCodesForm
                            formData={this.state.formData}
                            onSave={this.onSave}
                            onDelete={this.onDelete}
                            onCancel={this.onCancel}
                        />
                        <button class="btn btn-info" onClick={() => this.deleteCode(data.id)} style={{marginLeft:"3em"}}>Delete Old Codes</button>
                    </div>
                </div>
                <div className="panel-heading" style={panelHead}><h3 className="formHead">Codes</h3></div>

                <div className="panel-body" style={panelBody}>

                    <div className="containerr" >
                        <table className="table table-bordered" >
                            <thead>
                                <tr>
                                    <td><strong>ID</strong></td>
                                    <td><strong>Code</strong></td>
                                    <td><strong>Discount %</strong></td>
                                    <td><strong>Created At</strong></td>
                                    <td><strong>Expires On</strong></td>
                                    <td><strong>Status</strong></td>
                                    <td><strong>Uses</strong></td>
                                </tr>
                            </thead>
                            {/* <ReactPaginate
                              previousLabel={"← Previous"}
                              nextLabel={"Next →"}
                              pageRangeDisplayed={10}
                              pageCount={this.pageCount}
                              onPageChange={this.handlePageClick}
                            /> */}
                            <tbody>      
                                {data}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Promos;