import React from "react";
import * as validationHelper from "../helpers/validation.helper";
import * as adminsService from "../services/admin.service";
import { Redirect } from "react-router-dom";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        const formData = this.convertPropsToFormData(props);

        this.state = {
            formData: formData,
            formValid: false,
            staySignedIn: true,
            loginSuccess: false,
            registerHidden: true,
            loginHidden: false,
            loginError: false
        };

        this.onChange = validationHelper.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this)
        this.onSignIn = this.onSignIn.bind(this);
        this.staySignedIn = this.staySignedIn.bind(this);
        this.showPanels = this.showPanels.bind(this);
    }

    staySignedIn() {
        this.state.staySignedIn
            ? this.setState({ staySignedIn: false })
            : this.setState({ staySignedIn: true });
    }

    showPanels(e) {
        e.preventDefault();
        this.setState({ registerHidden: false });
        this.setState({ loginHidden: !this.state.loginHidden });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const formData = this.convertPropsToFormData(nextProps);
        this.setState({ formData: formData });
    }

    convertPropsToFormData(props) {
        const login = props.formData && props.formData._id ? props.formData : {};

        const initializedLogin = {
            email: login.email || "",
            password: login.password || ""
        };

        let formData = {
            email: {
                originalValue: initializedLogin.email,
                value: initializedLogin.email,
                valid: true,
                validation: {
                    required: true
                },
                touched: false
            },
            password: {
                originalValue: initializedLogin.password,
                value: initializedLogin.password,
                valid: true,
                validation: {
                    required: true
                },
                touched: false
            }
        };

        for (let fieldName in formData) {
            const field = formData[fieldName];
            field.valid = validationHelper.validate(field.value, field.validation);
        }

        return formData;
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this.onSignIn(event)
        }
    }

    onSignIn(event) {
        if (!this.state.formValid) {
            const formData = JSON.parse(JSON.stringify(this.state.formData));
            for (let fieldIdentifier in formData) {
                formData[fieldIdentifier].touched = false;
            }
            this.setState({ formData: formData });
        }
        let item = {
            email: this.state.formData.email.value,
            password: this.state.formData.password.value
        };
        adminsService
            .login(item)
            .then((response) => {
                console.log('response after login: ' + JSON.stringify(response))
                this.setState({ loginSuccess: true });
            })
            .catch(error => {
                this.setState({ loginError: true })
                console.log("There was error");
            });
    }
    render() {
        const cardStyle = {
            marginTop: '5%'
        }
        const cardStyle1 = {
            width: '35%',
            margin: '0 auto',
            justifyContent: "center",
            marginTop: '-3%'
        }
        return (

            <React.Fragment>
                {this.state.loginSuccess ? (
                    <React.Fragment>
                        <Redirect to="/stats" />
                    </React.Fragment>) : (
                        <React.Fragment>


                            <div className="container" style={cardStyle}>
                                <div className="">
                                    {this.state.loginError ? <div class="alert alert-danger" role="alert">
                                        ERROR: Login Failed
</div> : null}
                                    <div className="card">

                                        <div className="card-header">
                                            <h2 className="text-center">Printverse Pro Admin Panel</h2>
                                        </div>
                                        <div className="card-block" style={cardStyle1} >
                                            <form className="" >
                                                <div className="form-group">
                                                    <label
                                                    >
                                                        Email
                        </label>

                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        id="inputEmailForm"
                                                        placeholder="Email"
                                                        value={this.state.formData.email.value}
                                                        onChange={this.onChange}
                                                    />

                                                </div>
                                                <div className="form-group">
                                                    <label
                                                        className=""
                                                    >
                                                        Passsword
                        </label>

                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="inputPasswordForm"
                                                        placeholder="Password"
                                                        name="password"
                                                        value={this.state.formData.password.value}
                                                        onChange={this.onChange}
                                                        onKeyDown={(e) => this.onKeyDown(e)}
                                                    />

                                                </div>


                                                <div className="form-group">

                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-block"
                                                        onClick={this.onSignIn}
                                                    >
                                                        Sign-in
                          </button>


                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>


                            </div>


                        </React.Fragment>
                    )}
            </React.Fragment>
        );
    }
}

export default LoginForm;
