import axiosInstance from "../config/axios.config";
import * as env from "../config/env"
const headers = {};


const baseUrl = `${env.url}/api/companies`


export function readAll() {
    const config = {
        method: "GET",
        headers
    };
    return axiosInstance
        .get(baseUrl, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}


export function getCompanyId(company_name) {
    const config = {
        method: "GET",
        headers,
    };
    return axiosInstance(`${baseUrl}/${company_name}`, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = error => {
    console.log(error);
    return Promise.reject(error);
};
