import axiosInstance from "../config/axios.config";
import axios from "axios";
import * as env from "../config/env"
const headers = {};

const baseUrl = `${env.url}/api/orders`
const railsUrl = `${env.railsUrl}`


export function allOrderData() {
    const config = {
        method: "GET",
        headers
    };
    return axiosInstance.get(baseUrl + "/all", config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function allOrderItems(date_begin, date_end) {
    const config = {
        method: "POST",
        headers,
        data: {date_begin : date_begin, date_end: date_end}
    };
    return axiosInstance.post(baseUrl + "/all-order-items", config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function readAll() {
    const config = {
        method: "GET",
        headers
    };
    return axiosInstance
        .get(baseUrl, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function readOrderStatus() {
    const config = {
        method: "GET",
        headers
    };
    return axiosInstance
        .get(`${baseUrl}/status`, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function createOrder(data) {
    const config = {
        method: "POST",
        headers,
        data: data
    };
    return axios(baseUrl, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function readOrderJson(po_id) {
    const config = {
        method: "GET",
        headers,
    };
    return axios(`${baseUrl}/json/${po_id}`, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function update(orderData,id) {
    const config = {
      method: "PUT",
      headers,
      data: orderData
    };
  
    return axios(`${baseUrl}/${id}`, config)
      .then(responseSuccessHandler)
      .catch(responseErrorHandler);
  }
  
  export function saveOPOrder(orderData){
    const config = {
        method: "POST",
        headers,
        data: orderData
    }

    return axios(`${railsUrl}/order`, config).then(responseSuccessHandler).catch(responseErrorHandler)
}

export function uploadAwsImg(img){
    return axios.post(`${railsUrl}/file_library`, img).then(responseSuccessHandler).catch(responseErrorHandler)
}

const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = error => {
    console.log(error);
    return Promise.reject(error);
};