import axios from "axios";
import * as env from "../config/env"

const headers = {};


const baseUrl = `${env.url}/api/products`


export function create(productData) {
  const config = { 
    method: "POST",
    headers,
    data: productData
  };

  return axios(baseUrl, config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler);
}

export function readAll() {
  const config = {
    method: "GET",
    headers
  };

  return axios(baseUrl, config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler);
}

export function update(productData) {
  const config = {
    method: "PUT",
    headers,
    data: productData
  };

  return axios(`${baseUrl}/${productData.id}`, config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler);
}

export function del(id) {
  const config = {
    method: "DELETE",
    headers
  };

  return axios(`${baseUrl}/${id}`, config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
  return response.data;
};

const responseErrorHandler = error => {
  console.log(error);
  return Promise.reject(error);
};