import React from 'react';
import { Route } from 'react-router-dom';
import ErrorPage from './error';
import PrivateRoutes from './PrivateRoutes';
//import Orders from "./Orders";
import Stats from './Stats';
import Nav from './Nav';

class LayoutRouter extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Nav />
                <div className="App">
                    <div id="main" role="main">
                        <React.Fragment>
                            <Route exact path="/" component={Stats} />
                            <PrivateRoutes />
                            <Route path="/error" component={ErrorPage} />
                        </React.Fragment>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default LayoutRouter;
