
import axios from "axios";
import * as env from "../config/env"

const headers = {};


const baseUrl = `${env.url}/api/orderitems`


export function send_orderItem(itemData) {
    const config = {
      method: "POST",
      headers,
      data: itemData
    };
    return axios(baseUrl, config)
      .then(responseSuccessHandler)
      .catch(responseErrorHandler);
  }

  const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = error => {
    console.log(error);
    return Promise.reject(error);
};
