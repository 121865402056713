import React, { Component } from 'react'
import * as validationHelper from "../helpers/validation.helper";
import * as productService from "../services/product.service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ProductsForm extends Component {

    constructor(props) {
        super(props);

        const formData = this.convertPropsToFormData(props);

        this.state = {
            products: [],
            formData: formData,
            formValid: false
        };

        this.onChange = validationHelper.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const formData = this.convertPropsToFormData(nextProps);
        this.setState({ formData: formData });
    }

    convertPropsToFormData(props) {
        const product = props.formData && props.formData.id ? props.formData : {};

        const initializedProduct = {
            id: product.id || "",
            sku: product.sku || "",
            variant_name: product.variant_name || "",
            price: product.price || "",
            shipping_us: product.shipping_us || "",
            shipping_intl: product.shipping_intl || "",
            add_shipping: product.add_shipping || "",
            render_90_img_url: product.render_90_img_url || "",
            render_0_img_url: product.render_0_img_url || "",
            render_270_img_url: product.render_270_img_url || "",
            imprint_dimensions: product.imprint_dimensions || "",
            shipping_expedited: product.shipping_expedited || "",
            additional_shipping_expedited: product.additional_shipping_expedited || ""
        };

        let formData = {
            id: {
                originalValue: initializedProduct.id,
                value: initializedProduct.id,
                valid: true,
                validation: {},
                touched: false
            },
            sku: {
                originalValue: initializedProduct.sku,
                value: initializedProduct.sku,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            variant_name: {
                originalValue: initializedProduct.variant_name,
                value: initializedProduct.variant_name,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            price: {
                originalValue: initializedProduct.price,
                value: initializedProduct.price,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            shipping_us: {
                originalValue: initializedProduct.shipping_us,
                value: initializedProduct.shipping_us,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            shipping_intl: {
                originalValue: initializedProduct.shipping_intl,
                value: initializedProduct.shipping_intl,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            add_shipping: {
                originalValue: initializedProduct.add_shipping,
                value: initializedProduct.add_shipping,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            render_90_img_url: {
                originalValue: initializedProduct.render_90_img_url,
                value: initializedProduct.render_90_img_url,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 500
                },
                touched: false
            },
            render_0_img_url: {
                originalValue: initializedProduct.render_0_img_url,
                value: initializedProduct.render_0_img_url,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 500
                },
                touched: false
            },
            render_270_img_url: {
                originalValue: initializedProduct.render_270_img_url,
                value: initializedProduct.render_270_img_url,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 500
                },
                touched: false
            },
            imprint_dimensions: {
                originalValue: initializedProduct.imprint_dimensions,
                value: initializedProduct.imprint_dimensions,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            shipping_expedited: {
                originalValue: initializedProduct.shipping_expedited,
                value: initializedProduct.shipping_expedited,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            additional_shipping_expedited: {
                originalValue: initializedProduct.additional_shipping_expedited,
                value: initializedProduct.additional_shipping_expedited,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
        };

        for (let fieldName in formData) {
            const field = formData[fieldName];
            field.valid = validationHelper.validate(field.value, field.validation);
        }

        return formData;
    }

    onSave(event) {
        if (!this.state.formValid) {
            // Mark all fields as touched to display validation errors for all fields
            const formData = JSON.parse(JSON.stringify(this.state.formData));
            for (let fieldIdentifier in formData) {
                formData[fieldIdentifier].touched = false;
            }
            this.setState({ formData: formData });
            return;
        }
        const that = this;
        let item = {
            sku: this.state.formData.sku.value,
            variant_name: this.state.formData.variant_name.value,
            price: this.state.formData.price.value,
            shipping_us: this.state.formData.shipping_us.value,
            shipping_intl: this.state.formData.shipping_intl.value,
            add_shipping: this.state.formData.add_shipping.value,
            render_0_img_url: this.state.formData.render_0_img_url.value,
            render_90_img_url: this.state.formData.render_90_img_url.value,
            render_270_img_url: this.state.formData.render_270_img_url.value,
            imprint_dimensions: this.state.formData.imprint_dimensions.value,
            shipping_expedited: this.state.formData.shipping_expedited.value,
            additional_shipping_expedited: this.state.formData.additional_shipping_expedited.value,

        };

        if (this.state.formData.id.value.length > 0) {
            item.id = this.state.formData.id.value;
            productService
                .update(item)
                .then(data => {
                    that.props.onSave(item);
                    toast.info('Product was updated', {
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
                .catch(error => console.log(error));
        } else {
            productService
                .create(item)
                .then(data => {
                    // Modify state to reflect assigned id value
                    this.setState(prevState => {
                        const field = { ...prevState.formData.id, id: data };
                        const formData = { ...prevState.formData, id: field };
                        return { ...prevState, formData: formData };
                    });

                    that.props.onSave({ ...item, id: data.item });
                    toast.info('Product was saved', {
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
                .catch(error => console.log(error));
        }
    }

    render() {
        let panel_one = {
            width: '60%',
            marginLeft: '3em'
        }

        return (
            <React.Fragment>
                <div className="" id="accordion">
                    <div className="panel panel-default" style={panel_one}>
                        <div className="panel-heading" id="headingOne"><h3 className="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Enter Product Information
        </a></h3></div>
                        <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                            <div className="panel-body">
                                <form>


                                    <div className="row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="itemId">Product Id:</label>
                                            <input
                                                disabled
                                                type="text"
                                                name="id"
                                                id="itemId"
                                                className="form-control"

                                                value={this.state.formData.id.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.sku.valid &&
                                                    this.state.formData.sku.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Sku:</label>
                                            <input
                                                type="text"
                                                name="sku"
                                                id="sku"
                                                className="form-control"
                                                value={this.state.formData.sku.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.variant_name.valid &&
                                                    this.state.formData.variant_name.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Variant Name:</label>
                                            <input
                                                type="text"
                                                name="variant_name"
                                                id="variant_name"
                                                className="form-control"
                                                value={this.state.formData.variant_name.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.price.valid &&
                                                    this.state.formData.price.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Price:</label>
                                            <input
                                                type="text"
                                                name="price"
                                                id="price"
                                                className="form-control"
                                                value={this.state.formData.price.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.shipping_us.valid &&
                                                    this.state.formData.shipping_us.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Shipping US:</label>
                                            <input
                                                type="text"
                                                name="shipping_us"
                                                id="shipping_us"
                                                className="form-control"
                                                value={this.state.formData.shipping_us.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.shipping_intl.valid &&
                                                    this.state.formData.shipping_intl.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Shipping INTL:</label>
                                            <input
                                                type="text"
                                                name="shipping_intl"
                                                id="shipping_intl"
                                                className="form-control"
                                                value={this.state.formData.shipping_intl.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.add_shipping.valid &&
                                                    this.state.formData.add_shipping.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Additional Shipping:</label>
                                            <input
                                                type="text"
                                                name="add_shipping"
                                                id="add_shipping"
                                                className="form-control"
                                                value={this.state.formData.add_shipping.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.shipping_us.valid &&
                                                    this.state.formData.shipping_us.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Shipping Expedited:</label>
                                            <input
                                                type="text"
                                                name="shipping_expedited"
                                                id="shipping_expedited"
                                                className="form-control"
                                                value={this.state.formData.shipping_expedited.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.additional_shipping_expedited.valid &&
                                                    this.state.formData.additional_shipping_expedited.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Additional Shipping Expedited:</label>
                                            <input
                                                type="text"
                                                name="additional_shipping_expedited"
                                                id="additional_shipping_expedited"
                                                className="form-control"
                                                value={this.state.formData.additional_shipping_expedited.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className="form-group col-md-3"
                                        >
                                            <label htmlFor="name">Imprint Dimensions <small>(w/h)</small>:</label>
                                            <input
                                                type="text"
                                                name="imprint_dimensions"
                                                id="imprint_dimensions"
                                                className="form-control"
                                                value={this.state.formData.imprint_dimensions.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.render_90_img_url.valid &&
                                                    this.state.formData.render_90_img_url.touched
                                                    ? "form-group has-error col-md-6"
                                                    : "form-group col-md-6"
                                            }
                                        >
                                            <label htmlFor="name">Blank Image URL 90:</label>
                                            <input
                                                type="text"
                                                name="render_90_img_url"
                                                id="render_90_img_url"
                                                className="form-control"
                                                value={this.state.formData.render_90_img_url.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.render_0_img_url.valid &&
                                                    this.state.formData.render_0_img_url.touched
                                                    ? "form-group has-error col-md-6"
                                                    : "form-group col-md-6"
                                            }
                                        >
                                            <label htmlFor="name">Blank Image URL 0:</label>
                                            <input
                                                type="text"
                                                name="render_0_img_url"
                                                id="render_0_img_url"
                                                className="form-control"
                                                value={this.state.formData.render_0_img_url.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.render_270_img_url.valid &&
                                                    this.state.formData.render_270_img_url.touched
                                                    ? "form-group has-error col-md-6"
                                                    : "form-group col-md-6"
                                            }
                                        >
                                            <label htmlFor="name">Blank Image URL 270:</label>
                                            <input
                                                type="text"
                                                name="render_270_img_url"
                                                id="render_270_img_url"
                                                className="form-control"
                                                value={this.state.formData.render_270_img_url.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                    </div>

                                </form></div>

                            <div className="panel-footer">
                                <div className="" role="group">
                                    <button
                                        type="button"
                                        onClick={this.onSave}
                                        className="btn btn-primary btn-lg"
                                        disabled={!this.state.formValid}
                                    >
                                        Save
                </button>
                                    <div className="divider"></div>
                                    <button
                                        type="button"
                                        onClick={this.props.onCancel}
                                        className="btn btn-default btn-lg"
                                    >
                                        Cancel
                </button>
                                    <div className="divider"></div>
                                    <button
                                        type="button"
                                        onClick={() => this.props.onDelete(this.state.formData)}
                                        className="btn btn-danger btn-lg"
                                    >
                                        Delete
                </button>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default ProductsForm;
