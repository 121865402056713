import React from "react";
import LoginForm from "./LoginForm";


class Login extends React.Component {
  render() {
    return (
      <React.Fragment>
        <LoginForm />
      </React.Fragment>
    );
  }
}

export default Login;
