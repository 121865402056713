import axiosInstance from "../config/axios.config";
import axios from "axios";

import * as env from "../config/env"
const headers = {};

const baseUrl = `${env.url}/api/stripe`
const railsUrl = `${env.railsUrl}`


export function readAll() {
    const config = {
        method: "GET",
        headers
    };
    return axiosInstance
        .get(baseUrl, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}


export function getStripeIdByOpUserId(id) {
    const config = {
        method: "GET",
        headers
    };
    return axios(`${baseUrl}/${id}`, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}


export function processPayment(cardData) {
    const config = {
        method: "POST",
        headers,
        data: cardData
    }
    return axios(`${railsUrl}/charge`, config).then(responseSuccessHandler).catch(responseErrorHandler)
}

const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = error => {
    console.log(error);
    return Promise.reject(error);
};