import React from "react";
import AuthorizeRoleRoute from "./AuthorizeRoleRoute";
import * as adminServices from "../services/admin.service";
import { withRouter } from "react-router-dom";
import Shippings from "../components/Shippings";
import Products from '../components/Products'
import Users from '../components/Users'
import AdminForm from '../components/AdminForm'
import Errors from '../components/Errors'
import Orders from '../components/Orders'
import Companies from '../components/Companies'
import Promos from '../components/Promos'
import Stats from '../components/Stats'
import OpSubmission from '../components/OpSubmission';



class PrivateRoutes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      adminRole: "off"
    };
  }

  componentDidMount() {
    adminServices.getProfile().then(data => {
      this.setState({ adminRole: data.role })
    }).catch(err => {
      console.log('error: ' + JSON.stringify(err))
      this.setState({ adminRole: "No user" })
    })
  }


  render() {
    return this.state.adminRole !== undefined && this.state.adminRole !== "off" ? (
      <React.Fragment>
        <AuthorizeRoleRoute
          path="/stats"
          authorized={["Admin"]}
          adminRole={this.state.adminRole}
          component={Stats}
        />
        <AuthorizeRoleRoute
          path="/orders"
          authorized={["Admin","User"]}
          adminRole={this.state.adminRole}
          component={Orders}
        />        
        <AuthorizeRoleRoute
          path="/products"
          authorized={["Admin"]}
          adminRole={this.state.adminRole}
          component={Products}
        />
        <AuthorizeRoleRoute
          path="/users"
          authorized={["Admin"]}
          adminRole={this.state.adminRole}
          component={Users}
        />
        <AuthorizeRoleRoute
          path="/createuser"
          authorized={["Admin"]}
          adminRole={this.state.adminRole}
          component={AdminForm}
        />
        <AuthorizeRoleRoute
          path="/errors"
          authorized={["Admin"]}
          adminRole={this.state.adminRole}
          component={Errors}
        />
        <AuthorizeRoleRoute
          path="/companies"
          authorized={["Admin"]}
          adminRole={this.state.adminRole}
          component={Companies}
        />
        {/* <AuthorizeRoleRoute
          path="/shippings"
          authorized={["Admin"]}
          adminRole={this.state.adminRole}
          component={Shippings}
        /> */}
        <AuthorizeRoleRoute
          path="/promos"
          authorized={["Admin"]}
          adminRole={this.state.adminRole}
          component={Promos}
        />
        <AuthorizeRoleRoute
          path="/op-submissions"
          authorized={["Admin"]}
          adminRole={this.state.adminRole}
          component={OpSubmission}
        />
      </React.Fragment>
    ) : null;
  }
}
export default withRouter(PrivateRoutes)