import React from "react";
import * as shippingsService from "../services/shippings.service";

class Shippings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shippings: [],
    };
    
    this.onSelect = this.onSelect.bind(this);
  }
  
  componentDidMount() {
    shippingsService.readAll().then(data => {
      let arr = data.items.sort(function (a, b) {
        return parseInt(b.id) - parseInt(a.id)
      })
      this.setState({ shippings: arr })
    })
  }
  onSelect(item, event) {
    event.preventDefault();
    this.setState({
        formData: item
    });
  }
  
  render() {
    const shippings = this.state.shippings ? (
      this.state.shippings.map(shipping => (
          <tr className="notFirst" key={shipping.id} onClick={this.onSelect.bind(this, shipping)}>
              <td>{`${shipping.id}`}</td>
              <td>{`${shipping.name}`}</td>
              <td>{`${shipping.street_address}`}</td>
              <td>{`${shipping.city}`}</td>
              <td>{`${shipping.state}`}</td>
              <td>{`${shipping.zip_code}`}</td>
              <td>{`${shipping.country}`}</td>
              <td>{`${shipping.user_id}`}</td>
              <td>{`${shipping.street_address_2}`}</td>
              <td>{`${new Date(shipping.created_at).toLocaleDateString("en-US")} ${new Date(shipping.created_at).toLocaleTimeString("en-US")}`}</td>
          </tr >
      ))

  ) : (<React.Fragment />)

    let panelBody = {
        overflow: 'auto'
    }

    let panelHead = {
        marginLeft: '3em'
    }
    return (
      <React.Fragment>
        <div className="panel-heading" style={panelHead}><h3 className="formHead">Shippings</h3></div>

          <div className="panel-body" style={panelBody}>

            <div className="containerr" >
                <table className="table table-bordered" >
                    <thead>
                        <tr>
                            <td><strong>ID</strong></td>
                            <td><strong>Name</strong></td>
                            <td><strong>Street Address</strong></td>
                            <td><strong>City</strong></td>
                            <td><strong>State</strong></td>
                            <td><strong>Zipcode</strong></td>
                            <td><strong>Country</strong></td>
                            <td><strong>User ID</strong></td>
                            <td><strong>Street Address 2</strong></td>
                            <td><strong>Created At</strong></td>

                        </tr>
                    </thead>
                    <tbody>
                        {shippings}
                    </tbody>
                </table>
            </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Shippings;