import axiosInstance from "../config/axios.config";
import axios from "axios";
import * as env from "../config/env"
const headers = {};

const baseUrl = `${env.url}/api/heartbeat`
const railsUrl = `${env.railsUrl}`

export function getNodeStatus() {
  const config = {
    method: "GET",
    headers
  };
  return axiosInstance.get(baseUrl + "/nodebeat", config)
  //.then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getRailsStatus() {
  const config = {
    method: "GET",
    headers
  };
  return axios(railsUrl + "/heartbeats/all", config)
  //.then(responseSuccessHandler).catch(responseErrorHandler);
}

export function createHeartbeat() {
  let data = {
    status: 'ok',
  }
  const config = {
    method: "POST",
    headers,
    data
  };
  return axios(railsUrl + "/heartbeats", config)
}

// const responseSuccessHandler = response => {
//   return response.data;
// };

// const responseErrorHandler = error => {
//   console.log(error);
//   return Promise.reject(error);
// };