import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthorizeRoleRoute = props => {
  return <Route exact path={props.path} render={authorizeRoute(props)} />;
};

function authorizeRoute(props) {
  let Component = props.component;
  return () => {
    if (props.authorized.includes(props.adminRole)) {
      return <Component />;
    } else {
      return <Redirect to="/error" />;
    }
  };
}

export default AuthorizeRoleRoute;
