// function generateStr(length, orderId) {

//     let str = "";
//     for (; str.length < length; str += Math.random().toString(36).substr(2));
//     return str.substr(0, length) + orderId;
// }

export function generatePvpOrder(order, payStatus) {
    let pvpOrder = {}
    pvpOrder.op_order_id = order.orderId
    pvpOrder.company_id = order.companyId
    pvpOrder.user_id = order.userId
    pvpOrder.op_id = ""
    // pvpOrder.confirmation = generateStr(8, op_order_id)
    pvpOrder.po = order.po
    pvpOrder.order_time = order.order.order_info.order_datetime
    pvpOrder.ship_to_company = order.order.ship_to_info.ship_to_company || ""
    pvpOrder.fname = order.order.ship_to_info.ship_to_fname
    pvpOrder.lname = order.order.ship_to_info.ship_to_fname
    pvpOrder.address1 = order.order.ship_to_info.ship_to_street1
    pvpOrder.address2 = order.order.ship_to_info.ship_to_street2
    pvpOrder.city = order.order.ship_to_info.ship_to_city
    pvpOrder.state = order.order.ship_to_info.ship_to_stateprov
    pvpOrder.zip = order.order.ship_to_info.ship_to_zip
    pvpOrder.country = order.order.ship_to_info.ship_to_country
    pvpOrder.country_name = order.order.ship_to_info.ship_to_country_name
    pvpOrder.total_price = order.total || '-'
    pvpOrder.pay_type = 'Default Card'
    // pvpOrder.address_id = address_id
    pvpOrder.pay_status = payStatus



    return pvpOrder
}