import React from "react";
// import {withRouter} from 'react-router';
import * as companyService from "../services/company.service";
// import ProductsForm from "./ProductsForm";

class Companies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: []
        };
        this.onSelect = this.onSelect.bind(this);
    }

    componentDidMount() {
        companyService.readAll().then(data => {
            let arr = data.items.sort(function (a, b) {
                return parseInt(b.id) - parseInt(a.id)
            })
            this.setState({ companies: arr });
        });
    }

    onSave(updatedFormData) {
        this.setState(prevState => {
            const existingItem = prevState.companies.filter(item => {
                return item.id === updatedFormData.id;
            });
            let updatedItems = [];
            if (existingItem && existingItem.length > 0) {
                updatedItems = prevState.companies.map(item => {
                    return item.id === updatedFormData.id ? updatedFormData : item;
                });
            } else {
                updatedItems = prevState.companies.concat(updatedFormData);
            }
            return {
                companies: updatedItems,
                formData: null,
                errorMessage: null
            };
        });
    }

    onSelect(item, event) {
        event.preventDefault();
        this.setState({
            formData: item
        });
    }

    render() {



        const companies = this.state.companies ? (
            this.state.companies.map(company => (
                <tr className="notFirst" key={company.id} onClick={this.onSelect.bind(this, company)}>

                    <td>{`${company.id}`}</td>
                    <td>{`${company.user_id}`}</td>
                    <td>{`${company.company_id}`}</td>
                    <td>{`${company.company_name}`}</td>
                    <td>{`${new Date(company.created_at).toLocaleDateString("en-US")} ${new Date(company.created_at).toLocaleTimeString("en-US")}`}</td>

                </tr >

            ))

        ) : (<React.Fragment />)
        let panelBody = {
            overflow: 'auto'
        }

        let panelHead = {
            marginLeft: '3em'
        }
        return (
            <React.Fragment>
                <div className="panel-heading" style={panelHead}><h3 className="formHead">Companies</h3></div>

                <div className="panel-body" style={panelBody}>

                    <div className="containerr" >
                        <table className="table table-bordered" >
                            <thead>
                                <tr>
                                    <td><strong>ID</strong></td>
                                    <td><strong>PVP ID</strong></td>
                                    <td><strong>Company ID</strong></td>
                                    <td><strong>Company Name</strong></td>
                                    <td><strong>Created At</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                {companies}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Companies;