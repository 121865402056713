import axiosInstance from "../config/axios.config";
import axios from "axios";
import * as env from "../config/env"
const headers = {};

const baseUrl = `${env.url}/api/stats`;
const railsUrl = `${env.railsUrl}`;

export function getRefunds() {
  const config = {
    method: "GET",
    headers
  };
  return axiosInstance.get(baseUrl + "/refunds", config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getCycleTime() {
  const config = {
    method: "GET",
    headers
  };
  return axiosInstance.get(baseUrl + "/avgcycle", config).then(responseSuccessHandler).catch(responseErrorHandler);
}

export function getUsersLoggedIn() {
  const config = {
    method: "GET",
    headers
  };
  return axios.get(railsUrl + "/logins", config).then(responseSuccessHandler).catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
  return response.data;
};

const responseErrorHandler = error => {
  console.log(error);
  return Promise.reject(error);
};
