import React from "react";
import * as errorService from "../services/errors.service";
import 'react-toastify/dist/ReactToastify.css';


class Errors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: []
        };

        this.onCancel = this.onCancel.bind(this);
        // this.onDelete = this.onDelete.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
    }

    componentDidMount() {
        errorService.readAll().then(data => {
            let arr = data.items.sort(function (a, b) {
                return parseInt(b.id) - parseInt(a.id)
            })
            this.setState({ errors: arr});
        });
    }

    onCancel() {

        this.setState({ formData: null });
    }

    showModal = (id) => {
        // console.log("user id::: " + JSON.stringify(id))
        // termsService.readByUserId(id).then(data => {
        //     console.log("user terms data::: " + JSON.stringify(data))
        //     // const term_users = data.item
        //     this.setState({
        //         term_data: data.item
        //     })
        // })
        // this.setState({ showModal: true })
    }

    hideModal = () => {
        this.setState({
            showModal: false,
            term_data: []
        })
    }

    // onDelete() {
    //     const formData = this.state.formData;

    //     errorService
    //         .del(formData.id)
    //         .then(() => {
    //             this.setState(prevState => {
    //                 const updatedItems = prevState.admins.filter(item => {
    //                     return item.id !== formData.id;
    //                 });
    //                 toast.info('Admin was deleted', {
    //                     position: 'top-center',
    //                     autoClose: 3000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 })
    //                 return { admins: updatedItems };
    //             });

    //             this.onCancel();
    //         })
    //         .catch(err => console.log(err));
    // }

    onSave(updatedFormData) {
        this.setState(prevState => {
            const existingItem = prevState.errors.filter(item => {
                return item.id === updatedFormData.id;
            });
            let updatedItems = [];
            if (existingItem && existingItem.length > 0) {
                updatedItems = prevState.errors.map(item => {
                    return item.id === updatedFormData.id ? updatedFormData : item;
                });
            } else {
                updatedItems = prevState.errors.concat(updatedFormData);
            }
            return {
                errors: updatedItems,
                formData: null,
                errorMessage: null
            };
        });
    }

    onSelect(item, event) {
        event.preventDefault();
        this.setState({
            formData: item
        });
    }

    render() {

        const errors = this.state.errors ? (
            this.state.errors.map(errors => (
                <tr className="notFirst" key={errors.id} onClick={this.onSelect.bind(this, errors)}>
                    <td>{`${errors.id}`}</td>
                    <td>{`${errors.op_id == null ? '-': errors.op_id}`}</td>
                    <td>{`${errors.email}`}</td>
                    <td>{`${errors.message}`}</td>
                    <td>{`${errors.component}`}</td>
                    <td>{`${new Date(errors.created_at).toLocaleDateString("en-US")} ${new Date(errors.created_at).toLocaleTimeString("en-US")}`}</td>
                    {/* <td><button type="button" onClick={() => this.showModal(errors.id)} className="btn btn-default">View</button></td> */}
                </tr >

            ))

        ) : (<React.Fragment />)

        let panelBody = {
            overflow: 'auto'
        }

        let panelHead = {
            marginLeft: '3em'
        }
        return (
            <React.Fragment>

                {/* <div className="panel panel-default" style={style}> */}
                <div className="panel-heading" style={panelHead}><h3 className="formHead">Errors</h3></div>

                <div className="panel-body" style={panelBody}>

                    <div className="containerr" >
                        <table className="table table-bordered" >
                            <thead>
                                <tr>
                                    <td><strong>ID</strong></td>
                                    <td><strong>OpenPrint ID</strong></td>
                                    <td><strong>Email</strong></td>
                                    <td><strong>Message</strong></td>
                                    <td><strong>Component</strong></td>
                                    <td><strong>Created</strong></td>

                                    {/* <td><strong>Base</strong></td> */}
                                    {/* <td>Handling Fee</td> */}
                                </tr>
                            </thead>
                            <tbody>
                                {errors}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* </div> */}
            </React.Fragment>
        );
    }
}

export default Errors;