import React, { Component } from 'react'
import * as validationHelper from "../helpers/validation.helper";
import * as orderService from "../services/orders.service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class NewOrderForm extends Component {

    constructor(props) {
        super(props);

        const formData = this.convertPropsToFormData(props);

        this.state = {
            orderData: {},
            formData: formData,
            // formValid: false,
            customers: props.customers,
            selectedCustomer: props.selectedCustomer,
            user_id: props.pvpId,
            company_id: props.companyId,
            op_id: props.opId,
            payStatus: ['Paid', 'Refunded'],
            payTypes: ['paypal', 'default_card']
        };

        this.onChange = validationHelper.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSelectCustomer = this.onSelectCustomer.bind(this)
        this.onCancel = this.onCancel.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const formData = this.convertPropsToFormData(nextProps);
        this.setState({ formData: formData });
    }

    convertPropsToFormData(props) {
        const order = props.formData && props.formData.id ? props.formData : {};

        const initializedOrder = {
            id: order.id || "",
            // email: order.email,
            op_order_id: order.op_order_id || "",
            // company_id: order.company_id || "",
            // user_id: order.user_id || "",
            // op_id: order.op_id || "",
            // confirmation: order.confirmation || "",
            po: order.po || "",
            order_time: order.order_time || "",
            fname: order.fname || "",
            lname: order.lname || "",
            address1: order.address1 || "",
            address2: order.address2 || "",
            city: order.city || "",
            state: order.state || "",
            zip: order.zip || "",
            country: order.country || "",
            country_name: order.country_name || "",
            total_price: order.total_price || "",
            pay_status: order.pay_status || "",
            pay_type: order.pay_type || ""
        };

        let formData = {
            id: {
                originalValue: initializedOrder.id,
                value: initializedOrder.id,
                valid: true,
                validation: {},
                touched: false
            },
            op_order_id: {
                originalValue: initializedOrder.op_order_id,
                value: initializedOrder.op_order_id,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            po: {
                originalValue: initializedOrder.po,
                value: initializedOrder.po,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            // company_id: {
            //     originalValue: initializedOrder.company_id,
            //     value: initializedOrder.company_id,
            //     valid: true,
            //     validation: {
            //         required: true,
            //         maxLength: 50
            //     },
            //     touched: false
            // },
            // user_id: {
            //     originalValue: initializedOrder.user_id,
            //     value: initializedOrder.user_id,
            //     valid: true,
            //     validation: {
            //         required: true,
            //         maxLength: 50
            //     },
            //     touched: false
            // },
            // op_id: {
            //     originalValue: initializedOrder.op_id,
            //     value: initializedOrder.op_id,
            //     valid: true,
            //     validation: {
            //         required: true,
            //         maxLength: 50
            //     },
            //     touched: false
            // },
            order_time: {
                originalValue: initializedOrder.order_time,
                value: initializedOrder.order_time,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            fname: {
                originalValue: initializedOrder.fname,
                value: initializedOrder.fname,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            lname: {
                originalValue: initializedOrder.lname,
                value: initializedOrder.lname,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            address1: {
                originalValue: initializedOrder.address1,
                value: initializedOrder.address1,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            address2: {
                originalValue: initializedOrder.address2,
                value: initializedOrder.address2,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            city: {
                originalValue: initializedOrder.city,
                value: initializedOrder.city,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            state: {
                originalValue: initializedOrder.state,
                value: initializedOrder.state,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            zip: {
                originalValue: initializedOrder.zip,
                value: initializedOrder.zip,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            country: {
                originalValue: initializedOrder.country,
                value: initializedOrder.country,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            country_name: {
                originalValue: initializedOrder.country_name,
                value: initializedOrder.country_name,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            total_price: {
                originalValue: initializedOrder.total_price,
                value: initializedOrder.total_price,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            pay_status: {
                originalValue: initializedOrder.pay_status,
                value: initializedOrder.pay_status,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            },
            pay_type: {
                originalValue: initializedOrder.pay_type,
                value: initializedOrder.pay_type,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 10000
                },
                touched: false
            }
        };

        for (let fieldName in formData) {
            const field = formData[fieldName];
            field.valid = validationHelper.validate(field.value, field.validation);
        }

        return formData;
    }

    onSave(event) {
        // if (!this.state.formValid) {
        //     // Mark all fields as touched to display validation errors for all fields
        //     const formData = JSON.parse(JSON.stringify(this.state.formData));
        //     for (let fieldIdentifier in formData) {
        //         formData[fieldIdentifier].touched = false;
        //     }
        //     this.setState({ formData: formData });
        //     return;
        // }
        const that = this;
        let item = {


            // id: this.state.formData.id.value,
            // email: this.state.formData.email.value,
            op_order_id: this.state.formData.op_order_id.value,
            company_id: this.props.companyId,
            user_id: this.props.pvpId,
            op_id: this.props.opId,
            // company_id: this.state.formData.company_id.value,
            // user_id: this.state.formData.user_id.value,
            // op_id: this.state.formData.op_id.value,
            // confirmation: this.state.formData.sku.value,
            po: this.state.formData.po.value,
            order_time: this.state.formData.order_time.value,
            fname: this.state.formData.fname.value,
            lname: this.state.formData.lname.value,
            address1: this.state.formData.address1.value,
            address2: this.state.formData.address2.value,
            city: this.state.formData.city.value,
            state: this.state.formData.state.value,
            zip: this.state.formData.zip.value,
            country: this.state.formData.country.value,
            country_name: this.state.formData.country_name.value,
            total_price: this.state.formData.total_price.value,
            pay_status: this.state.formData.pay_status.value,
            pay_type: this.state.formData.pay_type.value,

        };

        if (this.state.formData.id.value > 0) {
            // item.id = this.state.formData.id.value;
            let _id = this.state.formData.id.value
            orderService
                .update(item, _id)
                .then(data => {
                    that.props.onSave(item);
                    toast.info('Order was updated', {
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
                .catch(error => console.log(error));
        } else {
            orderService
                .createOrder(item)
                .then(data => {
                    // Modify state to reflect assigned id value
                    this.setState(prevState => {
                        const field = { ...prevState.formData.id, id: data };
                        const formData = { ...prevState.formData, id: field };
                        return { ...prevState, formData: formData };
                    });

                    that.props.onSave({ ...item, id: data.item });
                    toast.info('Order was saved', {
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
                .catch(error => {
                    toast.error("Error: " + error.response.data.details[0].message, {
                        position: 'top-center',
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                });
        }
    }

    onSelectCustomer(event) {
        let that = this.props
        let selected = that.customers.filter(value => {
            return value.company_id == event.target.value
        })
        let idData = {
            company_id: selected[0].company_id,
            pvp_id: selected[0].id,
            op_user_id: selected[0].user_id
        }
        this.props.selectCustomer(event.target.value, idData)
    }

    onCancel() {
        this.props.onCancel()
        this.setState({ companyId: "", pvpId: "", opId: "" });
    }

    render() {
        let panel_one = {
            width: '100%',
            marginLeft: '3em'
        }
        const types = this.state.payTypes ? (
            this.state.payTypes.map((item, k) => (
                <option key={k} value={item}>{item === 'paypal' ? 'Paypal' : 'Stripe'}</option>
            ))
        ) : (<React.Fragment />)

        const statuses = this.state.payStatus ? (
            this.state.payStatus.map((item, i) => (
                <option value={item} key={i}>{item}</option>
            ))
        ) : (<React.Fragment />)

        const props = this.props
        const customers = props.customers ? (
            props.customers.map((item, j) => (
                <option value={item.company_id} key={j}>{item.company_id}:{" "}{item.username}</option>
            ))
        ) : (<React.Fragment />)
        return (
            <React.Fragment>
                <div className="" id="accordion">
                    <div className="panel panel-default" style={panel_one}>
                        <div className="panel-heading" id="headingOne"><h3 className="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Enter Order Information
        </a></h3></div>
                        <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                            <div className="panel-body">
                                <form>
                                    <div className="row">

                                        <div className="form-group col-md-2">
                                            <label htmlFor="itemId">ID:</label>
                                            <input
                                                disabled
                                                type="text"
                                                name="id"
                                                id="id"
                                                className="form-control"
                                                value={this.state.formData.id.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div className="form-group col-md-2">
                                            <label htmlFor="itemId">Customer:</label>
                                            <select

                                                type="text"
                                                name=""
                                                id=""
                                                className="form-control"
                                                value={props.selectedCustomer}
                                                onChange={this.onSelectCustomer}
                                            >
                                                <option value="" disabled>-Select Customer-</option>
                                                {customers}
                                            </select>
                                        </div>
                                        <div
                                            className=
                                            "form-group col-md-2"

                                        >
                                            <label htmlFor="name">Company ID:</label>
                                            <input
                                                disabled
                                                type="text"
                                                name="company_id"
                                                id="company_id"
                                                className="form-control"
                                                value={props.companyId}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div
                                            className="form-group col-md-2"
                                        >
                                            <label htmlFor="name">PVP User ID:</label>
                                            <input
                                                disabled
                                                type="text"
                                                name="user_id"
                                                id="user_id"
                                                className="form-control"
                                                value={props.pvpId}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div
                                            className="form-group col-md-2"
                                        >
                                            <label htmlFor="name">OP User ID:</label>
                                            <input
                                                disabled
                                                type="text"
                                                name="op_id"
                                                id="op_id"
                                                className="form-control"
                                                value={props.opId}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.op_order_id.valid &&
                                                    this.state.formData.op_order_id.touched
                                                    ? "form-group has-error col-md-2"
                                                    : "form-group col-md-2"
                                            }
                                        >
                                            <label htmlFor="name">OP Order ID:</label>
                                            <input
                                                type="text"
                                                name="op_order_id"
                                                id="op_order_id"
                                                className="form-control"
                                                value={this.state.formData.op_order_id.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.po.valid &&
                                                    this.state.formData.po.touched
                                                    ? "form-group has-error col-md-2"
                                                    : "form-group col-md-2"
                                            }
                                        >
                                            <label htmlFor="name">Partner Order ID:</label>
                                            <input
                                                type="text"
                                                name="po"
                                                id="po"
                                                className="form-control"
                                                value={this.state.formData.po.value}
                                                onChange={this.onChange}
                                            />

                                        </div>

                                        <div
                                            className={
                                                !this.state.formData.order_time.valid &&
                                                    this.state.formData.order_time.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Order Time:</label>
                                            <input
                                                type="text"
                                                name="order_time"
                                                id="order_time"
                                                className="form-control"
                                                value={this.state.formData.order_time.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.fname.valid &&
                                                    this.state.formData.fname.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">First Name:</label>
                                            <input
                                                type="text"
                                                name="fname"
                                                id="fname"
                                                className="form-control"
                                                value={this.state.formData.fname.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.lname.valid &&
                                                    this.state.formData.lname.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Last Name:</label>
                                            <input
                                                type="text"
                                                name="lname"
                                                id="lname"
                                                className="form-control"
                                                value={this.state.formData.lname.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.address1.valid &&
                                                    this.state.formData.address1.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Address 1:</label>
                                            <input
                                                type="text"
                                                name="address1"
                                                id="address1"
                                                className="form-control"
                                                value={this.state.formData.address1.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.address2.valid &&
                                                    this.state.formData.address2.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Address 2:</label>
                                            <input
                                                type="text"
                                                name="address2"
                                                id="address2"
                                                className="form-control"
                                                value={this.state.formData.address2.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.city.valid &&
                                                    this.state.formData.city.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">City:</label>
                                            <input
                                                type="text"
                                                name="city"
                                                id="city"
                                                className="form-control"
                                                value={this.state.formData.city.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.state.valid &&
                                                    this.state.formData.state.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">State:</label>
                                            <input
                                                type="text"
                                                name="state"
                                                id="state"
                                                className="form-control"
                                                value={this.state.formData.state.value}
                                                onChange={this.onChange}
                                            />

                                        </div>

                                        <div
                                            className={
                                                !this.state.formData.zip.valid &&
                                                    this.state.formData.zip.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Zip:</label>
                                            <input
                                                type="text"
                                                name="zip"
                                                id="zip"
                                                className="form-control"
                                                value={this.state.formData.zip.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.country.valid &&
                                                    this.state.formData.country.touched
                                                    ? "form-group has-error col-md-2"
                                                    : "form-group col-md-2"
                                            }
                                        >
                                            <label htmlFor="name">Country:</label>
                                            <input
                                                type="text"
                                                name="country"
                                                id="country"
                                                className="form-control"
                                                value={this.state.formData.country.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.country_name.valid &&
                                                    this.state.formData.country_name.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Country Name:</label>
                                            <input
                                                type="text"
                                                name="country_name"
                                                id="country_name"
                                                className="form-control"
                                                value={this.state.formData.country_name.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.total_price.valid &&
                                                    this.state.formData.total_price.touched
                                                    ? "form-group has-error col-md-2"
                                                    : "form-group col-md-2"
                                            }
                                        >
                                            <label htmlFor="name">Total Price:</label>
                                            <input
                                                type="text"
                                                name="total_price"
                                                id="total_price"
                                                className="form-control"
                                                value={this.state.formData.total_price.value}
                                                onChange={this.onChange}
                                            />
                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.pay_status.valid &&
                                                    this.state.formData.pay_status.touched
                                                    ? "form-group has-error col-md-2"
                                                    : "form-group col-md-2"
                                            }
                                        >
                                            <label htmlFor="name">Pay Status:</label>
                                            <select defaultValue="Choose One" type="text" name="pay_status" id="pay_status" className="form-control" value={this.state.formData.pay_status.value} onChange={this.onChange}>
                                                <option value="" disabled>-Select One-</option>
                                                {statuses}
                                            </select>
                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.pay_type.valid &&
                                                    this.state.formData.pay_type.touched
                                                    ? "form-group has-error col-md-2"
                                                    : "form-group col-md-2"
                                            }
                                        >
                                            <label htmlFor="name">Pay Type:</label>
                                            <select defaultValue="Choose One" type="text" name="pay_type" id="pay_type" className="form-control" value={this.state.formData.pay_type.value} onChange={this.onChange}>
                                                <option value="" disabled>-Select One-</option>

                                                {types}
                                            </select>
                                        </div>

                                    </div>

                                </form></div>

                            <div className="panel-footer">
                                <div className="" role="group">
                                    <button
                                        type="button"
                                        onClick={this.onSave}
                                        className="btn btn-primary btn-lg"
                                    // disabled={!this.state.formValid}
                                    >
                                        Save
                </button>
                                    {/* <button
                                        type="button"
                                        onClick={this.onSave}
                                        className="btn btn-primary btn-lg"
                                        disabled={!this.state.formValid}
                                    >
                                        Save
                </button> */}
                                    <div className="divider"></div>
                                    <button
                                        type="button"
                                        onClick={this.onCancel}
                                        className="btn btn-default btn-lg"
                                    >
                                        Cancel
                </button>
                                    <div className="divider"></div>
                                    {/* <button
                                        type="button"
                                        onClick={() => this.props.onDelete(this.state.formData)}
                                        className="btn btn-danger btn-lg"
                                    >
                                        Delete
                </button> */}
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default NewOrderForm;
