import React from "react";
// import {withRouter} from 'react-router';
import * as productService from "../services/product.service";
import ProductsForm from "./ProductsForm";

class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            products: []
        };

        this.onCancel = this.onCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.scroll = this.scroll.bind(this)
    }

    componentDidMount() {
        productService.readAll().then(data => {
            let arr = data.items.sort(function (a, b) {
                return parseInt(b.id) - parseInt(a.id)
            })
            this.setState({ products: arr });
        });
    }

    onCancel() {
        this.setState({ formData: null });
    }

    onDelete() {
        const formData = this.state.formData;

        productService
            .del(formData.id)
            .then(() => {
                this.setState(prevState => {
                    const updatedItems = prevState.products.filter(item => {
                        return item.id !== formData.id;
                    });

                    return { products: updatedItems };
                });

                this.onCancel();
            })
            .catch(err => console.log(err));
    }

    onSave(updatedFormData) {
        this.setState(prevState => {
            const existingItem = prevState.products.filter(item => {
                return item.id === updatedFormData.id;
            });
            let updatedItems = [];
            if (existingItem && existingItem.length > 0) {
                updatedItems = prevState.products.map(item => {
                    return item.id === updatedFormData.id ? updatedFormData : item;
                });
            } else {
                updatedItems = prevState.products.concat(updatedFormData);
            }
            return {
                products: updatedItems,
                formData: null,
                errorMessage: null
            };
        });
    }

    onSelect(item, event) {

        // event.preventDefault();
        this.setState({
            formData: item
        });
    }

    scroll() {
        window.scrollTo({ top: 0, behavior: 'smooth' });

    }

    render() {



        const products = this.state.products ? (
            this.state.products.map(product => (
                <tr className="notFirst" key={product.id} onClick={this.onSelect.bind(this, product)}>


                    <td><input type="checkbox"></input>
                        <div onClick={this.scroll.bind(this, product)}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                <path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
                            </svg>
                        </div></td>
                    {/* <td>{`${product.id}`}</td> */}
                    <td>{`${product.sku}`}</td>
                    <td><a href={`${product.blank_img_url}`}>{`${product.variant_name}${product.color !== null ? " - " + product.color : ""}`}</a></td>
                    <td>{`${product.price}`}</td>
                    <td>{`${product.shipping_us}`}</td>
                    <td>{`${product.shipping_intl}`}</td>
                    <td>{`${product.add_shipping}`}</td>
                    <td>{`${product.shipping_expedited}`}</td>
                    <td>{`${product.additional_shipping_expedited}`}</td>
                    {/* <td><a href={`${product.blank_img_url}`}>{`${product.blank_img_url}`}</a></td> */}
                    <td>{`${product.imprint_dimensions}`}</td>
                </tr >

            ))

        ) : (<React.Fragment />)
        let panelBody = {
            overflow: 'auto'
        }

        let panelHead = {
            marginLeft: '3em'
        }
        return (
            <React.Fragment>

                <ProductsForm
                    formData={this.state.formData}
                    onSave={this.onSave}
                    onDelete={this.onDelete}
                    onCancel={this.onCancel}
                />


                {/* <div className="panel panel-default" style={style}> */}
                <div className="panel-heading" style={panelHead}><h3 className="formHead">Products</h3></div>

                <div className="panel-body" style={panelBody}>

                    <div className="containerr" >
                        <table className="table table-bordered" >
                            <thead>
                                <tr>
                                    <td><strong></strong></td>
                                    {/* <td><strong>ID</strong></td> */}
                                    <td><strong>SKU</strong></td>
                                    <td><strong>Variant_Name</strong></td>
                                    <td><strong>Price</strong></td>
                                    <td><strong>Shipping US</strong></td>
                                    <td><strong>Shipping INTL</strong></td>
                                    <td><strong>Additional Shipping</strong></td>
                                    <td><strong>Shipping Expedited</strong></td>
                                    <td><strong>Additional Shipping Expedited</strong></td>
                                    {/* <td><strong>Blank Image URL</strong></td>  */}
                                    <td><strong>Imprint Dimensions</strong></td>
                                </tr>
                            </thead>
                            <tbody>
                                {products}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* </div> */}
            </React.Fragment>
        );
    }
}

export default Products;