import React from "react";
import { Route} from "react-router-dom";
import Login from "./Login"

const NoLayoutRouter = ({ match }) => {

    return (
        <React.Fragment>
                <Route path={`${match.url}/login`} component={Login} />
        </React.Fragment>
    );
}

export default NoLayoutRouter;