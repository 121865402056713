import React from "react";
import * as userService from "../services/users.service";
import * as termsService from "../services/terms.service";
import Modal from './Modal'



class Users extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            term_users: [],
            showModal: false,
            _data: []
        }

        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
    }



    componentDidMount() {
        userService.readAll().then(data => {
            let arr = data.items.sort(function (a, b) {
                return parseInt(b.id) - parseInt(a.id)
            })
            this.setState({
                users: arr
            })

        })
    }


    showModal = (id) => {
        termsService.readByUserId(id).then(data => {
            this.setState({
                term_data: data.item
            })
        })
        this.setState({ showModal: true })
    }

    hideModal = () => {
        this.setState({
            showModal: false,
            term_data: []
        })
    }



    render() {

        const users = this.state.users ? (
            this.state.users.map((user, i) => (
                <tr className="notFirst" key={i}>
                    {/* <td><button type="button" onClick={() => this.showModal(user.id)} className="btn btn-default">View</button></td> */}
                    <td>{`${user.id}`}</td>
                    <td>{`${user.user_id == null ? "-" : user.user_id}`}</td>
                    <td>{`${user.company_id}`}</td>
                    <td>{`${user.username}`}</td>
                    <td>{`${user.email}`}</td>
                    {/* <td>{`${user.bill_method}`}</td> */}
                    {/* <td>{`${user.terms}`}</td> */}
                    <td>{`${new Date(user.created_at).toLocaleDateString("en-US")} ${new Date(user.created_at).toLocaleTimeString("en-US")}`}</td>
                </tr>
            ))
        ) : (<React.Fragment />)

        let panelBody = {
            overflow: 'auto'
        }

        let panelHead = {
            marginLeft: '3em'
        }
        return (
            <React.Fragment>
                <div className="panel-heading" style={panelHead}><h3 className="formHead">Users</h3></div>

                <div className="panel-body" style={panelBody}>

                    <div className="containerr" >
                        <table className="table table-bordered" >
                            <thead>
                                <tr>
                                    <td><strong>ID</strong></td>
                                    <td><strong>OpenPrint ID</strong></td>
                                    <td><strong>OP Company ID</strong></td>
                                    <td><strong>Name</strong></td>
                                    <td><strong>Email</strong></td>
                                    {/* <td><strong>Bill Method</strong></td> */}
                                    {/* <td><strong>Terms</strong></td> */}
                                    <td><strong>Date Created</strong></td>

                                </tr>
                            </thead>
                            <tbody>
                                {users}
                            </tbody>
                        </table>
                        {this.state.showModal && this.state.term_data && <Modal data={this.state.term_data} handleClose={this.hideModal} />}
                        {/* {!this.state.showModal && <Modal data={this.state._data} show={this.state.showModal} handleClose={this.hideModal} />} */}
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Users;