import React, { Component } from 'react'
import * as validationHelper from "../helpers/validation.helper";
import * as promoService from "../services/promos.service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import '../css/datepicker.css'

class PromoCodesForm extends Component {

    constructor(props) {
        super(props);

        const formData = this.convertPropsToFormData(props);

        this.state = {
            formData: formData,
            codeLength: "",
            selectedDate: ""
        };
        this.onChange = validationHelper.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
        // this.generateCodes = this.generateCodes.bind(this);
        this.onSelectCustomer = this.onSelectCustomer.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onDateChange = this.onDateChange.bind(this)
       // this.onDelete = this.onDelete.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const formData = this.convertPropsToFormData(nextProps);
        this.setState({ formData: formData });
    }

    convertPropsToFormData(props) {
        const promo = props.formData && props.formData.id ? props.formData : {};

        const initializedPromo = {
            id: promo.id || "",
            code: promo.code || "",
            discount: promo.discount || "",
            quantity: promo.quantity || "",
            prefix: promo.prefix || "",
            codeLength: promo.codeLength || "",
            expires_at: promo.expires_at || "",
            uses: promo.uses || "",

        };

        let formData = {
            id: {
                originalValue: initializedPromo.id,
                value: initializedPromo.id,
                valid: true,
                validation: {},
                touched: false
            },
            codeLength: {
                originalValue: initializedPromo.codeLength,
                value: initializedPromo.codeLength,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            quantity: {
                originalValue: initializedPromo.quantity,
                value: initializedPromo.quantity,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            discount: {
                originalValue: initializedPromo.discount,
                value: initializedPromo.discount,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            expires_at: {
                originalValue: initializedPromo.expires_at,
                value: initializedPromo.expires_at,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50,
                },
                touched: false
            },
            prefix: {
                originalValue: initializedPromo.prefix,
                value: initializedPromo.prefix,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50,
                },
                touched: false
            },
            uses: {
                originalValue: initializedPromo.uses,
                value: initializedPromo.uses,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },

        };

        for (let fieldName in formData) {
            const field = formData[fieldName];
            field.valid = validationHelper.validate(field.value, field.validation);
        }

        return formData;
    }

    onDateChange(date) {
        this.setState({
            selectedDate: date
        })
    }

    // onDelete(){
    //     promoService.deleteCodes(this.state.formData.id)
    //     .then((response) => {
    //         console.log(response)
    //     })
    //     .catch(err => console.log(err))
    // }
    onSave(event) {

        const that = this;
        let item = {
            discount: this.state.formData.discount.value,
            codeLength: this.state.formData.codeLength.value,
            quantity: this.state.formData.quantity.value,
            expires_at: this.state.selectedDate == "" ? "" : new Date(this.state.selectedDate).toISOString().split("T")[0] + " " + "23:59:59",
            prefix: this.state.formData.prefix.value,
            uses: this.state.formData.uses.value,
        };

        // if (this.state.formData.id.value > 0) {
        //     let _id = this.state.formData.id.value
        //     promoService
        //         .update(item, _id)
        //         .then(data => {
        //             that.props.onSave(item);
        //             toast.info('Order was updated', {
        //                 position: 'top-center',
        //                 autoClose: 3000,
        //                 hideProgressBar: false,
        //                 closeOnClick: true,
        //                 pauseOnHover: true,
        //                 draggable: true,
        //                 progress: undefined,
        //             })
        //         })
        //         .catch(error => console.log(error));
        // } else {
        promoService
            .create(item)
            .then(data => {
                this.setState(prevState => {
                    const field = { ...prevState.formData.id, id: data };
                    const formData = { ...prevState.formData, id: field };
                    return { ...prevState, formData: formData };
                });

                that.props.onSave(data);
                toast.info('Promos were saved', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
            .catch(error => {
                toast.error("Error: " + error.message, {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            });
        // }
    }

    onSelectCustomer(event) {
        let that = this.props
        let selected = that.customers.filter(value => {
            return value.company_id == event.target.value
        })
        let idData = {
            company_id: selected[0].company_id,
            pvp_id: selected[0].id,
            op_user_id: selected[0].user_id
        }
        this.props.selectCustomer(event.target.value, idData)
    }

    onCancel() {
        this.props.onCancel()
        this.setState({ companyId: "", pvpId: "", opId: "" });
    }

    render() {
        let panel_one = {
            width: '100%',
            marginLeft: '3em'
        }
        const props = this.props

        return (
            <React.Fragment>
                <div className="" id="accordion">

                    <div className="panel panel-default" style={panel_one}>
                        <div className="panel-heading" id="headingOne"><h3 className="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Generate Promotional Codes
        </a></h3></div>
                        <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                            <div className="panel-body">
                                <form>
                                    <div className="row">
                                        <div
                                            className={
                                                !this.state.formData.prefix.valid &&
                                                    this.state.formData.prefix.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Prefix:</label>
                                            <input
                                                type="text"
                                                name="prefix"
                                                id="prefix"
                                                className="form-control"
                                                value={this.state.formData.prefix.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.codeLength.valid &&
                                                    this.state.formData.codeLength.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Code Length:</label>
                                            <input
                                                type="text"
                                                name="codeLength"
                                                id="codeLength"
                                                className="form-control"
                                                value={this.state.formData.codeLength.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.discount.valid &&
                                                    this.state.formData.discount.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Discount:</label>
                                            <input
                                                type="text"
                                                name="discount"
                                                id="discount"
                                                className="form-control"
                                                value={this.state.formData.discount.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.quantity.valid &&
                                                    this.state.formData.quantity.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Quantity:</label>
                                            <input
                                                type="text"
                                                name="quantity"
                                                id="quantity"
                                                className="form-control"
                                                value={this.state.formData.quantity.value}
                                                onChange={this.onChange}
                                            />

                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="name">Expires At:</label>
                                            <div className="datepicker">
                                                <DatePicker popperPlacement='right-start' wrapperClassName="datepicker" className="form-control" selected={this.state.selectedDate} onChange={this.onDateChange} />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.uses.valid &&
                                                    this.state.formData.uses.touched
                                                    ? "form-group has-error col-md-3"
                                                    : "form-group col-md-3"
                                            }
                                        >
                                            <label htmlFor="name">Uses:</label>
                                            <input
                                                type="text"
                                                name="uses"
                                                id="uses"
                                                className="form-control"
                                                value={this.state.formData.uses.value}
                                                onChange={this.onChange}
                                            />

                                        </div>

                                    </div>
                                </form></div>

                            <div className="panel-footer">
                                <div className="" role="group">
                                    <button
                                        type="button"
                                        onClick={this.onSave}
                                        className="btn btn-primary btn-lg"
                                    >
                                        Generate
                </button>

                                    <div className="divider"></div>
                                    <button
                                        type="button"
                                        onClick={this.onCancel}
                                        className="btn btn-default btn-lg"
                                    >
                                        Cancel
                </button>
                                    <div className="divider"></div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment >
        );
    }
}

export default PromoCodesForm;
