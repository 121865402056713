import React from "react";
import "../css/modal.css"

const Modal = ({ data, handleClose, show, children, po }) => {

    const listStyle={
        width:'100%',
        height:'100%'
    }
    let _curr_order = po
    const order_data = data ? (
        data.map((item,i) => (
            <div style={listStyle} key={i}>
                <textarea disabled rows='30' className="form-control">{JSON.stringify(JSON.parse(item.order_data), null, 2)}</textarea>
            </div>
        ))
    ) : (<React.Fragment><span>Cannot GET order data.</span></React.Fragment>)
    return (
        <React.Fragment>

            <div className="modall display-block" tabIndex="-1" role="dialog">
                {/* <div class={showHideClassName} tabindex="-1" role="dialog"> */}
                <div className="modal-main" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h5 className="modal-title">Order Data for: {_curr_order} </h5>

                        </div>
                        <div className="modal-body">
                            {order_data}
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                            <button onClick={handleClose} type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>





        </React.Fragment>

    );
}



export default Modal;