import React, { Component } from 'react';
import * as validationHelper from '../helpers/validation.helper';
import * as adminService from '../services/admin.service';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AdminForm extends Component {
    constructor(props) {
        super(props);

        const formData = this.convertPropsToFormData(props);

        this.state = {
            admins: [],
            formData: formData,
            formValid: false,
            checked: false,
            isPosted: null
        };

        this.onChange = validationHelper.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onTest = this.onTest.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    onCancel() {
        this.setState({ formData: null });
        console.log('cancelled');
    }

    toggleAdmin() {
        if (this.state.checked != false) {
            this.setState({ checked: false });
        } else {
            this.setState({ checked: true });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const formData = this.convertPropsToFormData(nextProps);
        this.setState({ formData: formData });
    }

    onTest(e) {
        console.log('inside on test:');
        toast.success('Admin user was saved!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }

    convertPropsToFormData(props) {
        const admin = props.formData && props.formData.id ? props.formData : {};

        const initializedAdmin = {
            id: admin.id || '',
            email: admin.email || '',
            password: admin.password || ''
        };
        let formData = {
            id: {
                originalValue: initializedAdmin.id,
                value: initializedAdmin.id,
                valid: true,
                validation: {},
                touched: false
            },
            email: {
                originalValue: initializedAdmin.email,
                value: initializedAdmin.email,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            },
            password: {
                originalValue: initializedAdmin.password,
                value: initializedAdmin.password,
                valid: true,
                validation: {
                    required: true,
                    maxLength: 50
                },
                touched: false
            }
        };

        for (let fieldName in formData) {
            const field = formData[fieldName];
            field.valid = validationHelper.validate(
                field.value,
                field.validation
            );
        }

        return formData;
    }

    onSave(event) {
        if (!this.state.formValid) {
            // Mark all fields as touched to display validation errors for all fields
            const formData = JSON.parse(JSON.stringify(this.state.formData));
            for (let fieldIdentifier in formData) {
                formData[fieldIdentifier].touched = false;
            }
            this.setState({ formData: formData });
            return;
        }
        const that = this;

        let role;

        // if (this.props.isAdmin) {
        //     role = 'Admin';
        // } else {
        //     role = 'User';
        // }

        if (this.state.checked) {
            role = 'Admin';
        } else {
            role = 'User';
        }

        let item = {
            email: this.state.formData.email.value,
            password: this.state.formData.password.value,
            role: role
        };

        if (this.state.formData.id.value.length > 0) {
            item.id = this.state.formData.id.value;
            adminService
                .update(item)
                .then((data) => {
                    that.props.onSave(item);
                })
                .catch((error) => console.log(error));
        } else {
            console.log(this.state.formData);
            console.log(this.props.isAdmin);
            console.log(item);

            adminService
                .create(item)
                .then((data) => {
                    // Modify state to reflect assigned id value
                    this.setState({ isPosted: true });
                    this.setState((prevState) => {
                        const field = { ...prevState.formData.id, id: data };
                        const formData = { ...prevState.formData, id: field };
                        return { ...prevState, formData: formData };
                    });

                    //   that.props.onSave({ ...item, id: data.item });
                    // toast.info('Admin was saved', {
                    //     position: 'top-center',
                    //     autoClose: 3000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    // })
                })

                .catch((error) => {
                    console.log(error);
                    this.setState({ isPosted: false });
                });
        }
    }

    render() {
        let panel_one = {
            width: '40%',
            marginLeft: '3em'
        };

        return (
            <React.Fragment>
                <div className="form-wrapper" role="dialog">
                    <div className="form-content">
                        <div className="" id="headingOne">
                            <h3 className="panel-title">
                                <a
                                    role="button"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Enter New User
                                </a>
                            </h3>
                        </div>
                        <div
                            id="collapseOne"
                            className="panel-collapse collapse in"
                            role="tabpanel"
                            aria-labelledby="headingOne"
                        >
                            <div className="panel-body">
                                <form>
                                    <div className="row">
                                        <div
                                            className={
                                                !this.state.formData.email
                                                    .valid &&
                                                this.state.formData.email
                                                    .touched
                                                    ? 'form-group has-error col-md-7'
                                                    : 'form-group col-md-7'
                                            }
                                        >
                                            <label htmlFor="name">Email:</label>
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                className="form-control"
                                                value={
                                                    this.state.formData.email
                                                        .value
                                                }
                                                onChange={this.onChange}
                                            />
                                            {!this.state.formData.email.valid &&
                                            this.state.formData.email
                                                .touched ? (
                                                <p className="text-danger">
                                                    Collections is required.
                                                </p>
                                            ) : null}
                                        </div>
                                        <div
                                            className={
                                                !this.state.formData.password
                                                    .valid &&
                                                this.state.formData.password
                                                    .touched
                                                    ? 'form-group has-error col-md-7'
                                                    : 'form-group col-md-7'
                                            }
                                        >
                                            <label htmlFor="name">
                                                Password:
                                            </label>
                                            <input
                                                type="password"
                                                name="password"
                                                id="password"
                                                className="form-control"
                                                value={
                                                    this.state.formData.password
                                                        .value
                                                }
                                                onChange={this.onChange}
                                            />
                                            {!this.state.formData.password
                                                .valid &&
                                            this.state.formData.password
                                                .touched ? (
                                                <p className="text-danger">
                                                    Password is required.
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div>
                                        <label>Admin Rights</label>
                                        <input
                                            type="checkbox"
                                            value={this.state.checked}
                                            onClick={() => {
                                                this.toggleAdmin();
                                            }}
                                        />
                                    </div>
                                </form>
                            </div>

                            <div className="panel-footer">
                                <div className="" role="group">
                                    <button
                                        type="button"
                                        onClick={this.onSave}
                                        className="btn btn-primary btn-lg"
                                        disabled={!this.state.formValid}
                                    >
                                        Save
                                    </button>
                                    <div className="divider"></div>
                                </div>
                                <div className="clearfix"></div>
                                <div>
                                    {this.state.isPosted === true ? (
                                        <div
                                            style={{
                                                color: 'green',
                                                padding: '8px'
                                            }}
                                        >
                                            <h5>User Creation Successful.</h5>
                                        </div>
                                    ) : this.state.isPosted === false ? (
                                        <div
                                            style={{
                                                color: 'red',
                                                padding: '8px'
                                            }}
                                        >
                                            <h5>Error Creating User.</h5>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AdminForm;
