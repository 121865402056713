
//export const url = "http://localhost:8082/admin";
// export const railsUrl = "http://localhost:3000";
// export const testUrl = "https://test-orders.printverse.com";
// export const prodUrl = "https://orders.printverse.com";


// dev
// export const railsUrl = "http://13.57.231.51";

//production
// export const url = "https://admin.printversepro.com/admin";
// export const railsUrl = "https://services.printversepro.com";


export const env = "production" //possible values: production, development
export const opUrl = getOPEndpoint();
export const railsUrl = getRailsEndpoint();
export const url = getUrlEndpoint();

//for op
function getOPEndpoint() {
    let url
    if (env === "development") {
      url = "https://test-orders.printverse.com"
    } else if (env === "production") {
      url = "https://orders.printversepro.com"
    } else {
      url = "https://test-orders.printverse.com"
    }
    return url
  }

  //for rails
  function getRailsEndpoint() {
    let url
    if (env === "development") {
      url = "http://localhost:3000"
    } else if (env === "production") {
      url = "https://services.printversepro.com"
    } else {
      url = "http://localhost:3000"
    }
    return url
  }

  //pvp url
  function getUrlEndpoint() {
    let url
    if (env === "development") {
      url = "http://localhost:8082/admin"
    } else if (env === "production") {
      url = "https://admin.printversepro.com/admin"
    } else {
      url = "http://localhost:8082/admin"
    }
    return url
  }




