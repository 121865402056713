import axios from "axios";

import * as env from "../config/env"

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'OP-USER-TOKEN': 'fH57Jti'
};

const liveUrl = `${env.url}/api/openprint`

// const opURL = `${env.opUrl}/apis/send_order`
const baseUrl = `${env.url}/api`


export function send_order(orderData) {
    const config = {
        method: "POST",
        headers,
        data: orderData
    };
    return axios(baseUrl + "/openprint" , config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = error => {

    return Promise.reject(error);
};