export function generateOrder(data) {
    const op = {
        order: {
            order_info: {},
            ship_from_info: {},
            ship_to_info: {},
            order_items: { order_item: [] },
            order_attachments: {}
        }
    }
    let partner_order_id = Math.random().toString(36).substr(2, 5)
    let today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + ' ' + time;

    if (data.sku.length) {

        for (let i = 0; i < data.sku.length; i++) {
            const item = {
                order_item_product_code: data.sku[i],
                order_item_partner_product_code: data.sku[i],
                order_item_quantity: data.quantities[i],
                order_item_image_url: data.imageUrl[i],
                order_item_image_ftp: ""
            }
            op.order.order_items.order_item.push(item)
        }
    } else {
        const item = {
            order_item_product_code: data.sku ,
            order_item_partner_product_code: data.sku,
            order_item_quantity: '1',
            order_item_image_url: data.imageUrl,
            order_item_image_ftp: ""
        }
        op.order.order_items.order_item.push(item)
    }

    

    //commit parameters to vuex store
    op.order.order_info.partner_order_id = partner_order_id || ""
    op.order.order_info.username = data.company || ""
    op.order.order_info.token = data.token || ""
    op.order.order_info.order_datetime = dateTime
    op.order.order_info.source = "pvp.com"
    op.order.order_info.callback_url = `https://services.printversepro.com/order_info/${partner_order_id}`
    op.order.ship_from_info.ship_from_company = "Photo USA"
    op.order.ship_from_info.ship_from_fname = "Fulfillment"
    op.order.ship_from_info.ship_from_lname = "Center"
    op.order.ship_from_info.ship_from_street1 = "46595 Landing Pkwy"
    op.order.ship_from_info.ship_from_street2 = ""
    op.order.ship_from_info.ship_from_street3 = ""
    op.order.ship_from_info.ship_from_city = "Fremont"
    op.order.ship_from_info.ship_from_stateprov = "CA"
    op.order.ship_from_info.ship_from_country = "US"
    op.order.ship_from_info.ship_from_country_name = "United States"
    op.order.ship_from_info.ship_from_zip = "94538"
    op.order.ship_from_info.ship_from_phone = "0000000000"
    op.order.ship_from_info.ship_from_email = "info@photomugs.com"

    op.order.ship_to_info.ship_to_company = data.company || ""
    op.order.ship_to_info.ship_to_fname = data.firstName || ""
    op.order.ship_to_info.ship_to_lname = data.lastName || ""
    op.order.ship_to_info.ship_to_street1 = data.address1 || ""
    op.order.ship_to_info.ship_to_street2 = data.address2 || ""
    op.order.ship_to_info.ship_to_street3 = data.address3 || ""
    op.order.ship_to_info.ship_to_city = data.city || ""
    op.order.ship_to_info.ship_to_stateprov = data.state || ""
    op.order.ship_to_info.ship_to_country = data.countryCode || ""
    op.order.ship_to_info.ship_to_country_name = data.country || ""
    op.order.ship_to_info.ship_to_zip = data.zip || ""
    op.order.ship_to_info.ship_to_email = data.email || 'fake@gmail.com'
    op.order.ship_to_info.ship_to_method = data.shippingCarrier || ""
    op.order.ship_to_info.ship_to_code = data.shippingMethod || ""

    op.order.order_attachments.order_packlist = "DEFAULT"
    op.order.order_attachments.order_packlist_ftp = ""
    
    return op
}