import React, { Component } from 'react'
import * as orderService from "../services/orders.service";
import * as validationHelper from "../helpers/validation.helper";
import * as opService from "../services/openprint.service";
import * as orderData from "../services/orderdata.service";
import * as productService from "../services/product.service";
import * as labelsService from "../services/labels.service";
import * as jsonAuthService from "../services/jsonauth.service";
import * as orderitemService from "../services/orderitems.service";
import * as companyService from "../services/company.service";
import * as userService from "../services/users.service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'js-cookie';


class OrdersForm extends Component {   
  constructor(props) {
  
  super(props);
    
  const formData = this.convertPropsToFormData(props);

  this.state = {
   orderData: {},
   formData: formData,
   products: [],
   temp_products: [],
   message: '',
   loginError: false,
   user: '',
   password: '',
   isLoggedIn: false,
   isPosted: null,
   opResponse: '',
   po_id: '',
   first_name: '',
   last_name: '',
   address1: '',
   address2: '',
   city: '',
   state: '',
   zip_code: '',
   country:'',
   country_name: '',
   sku: '',
   quantity:'',
   artwork_url: '',
   fileData: '',
   rawFile: '',
   awsImgLink: '',
 }; 
   this.handleSubmit = this.handleSubmit.bind(this);
   this.onChange = validationHelper.onChange.bind(this);
   this.onInputchange = this.onInputchange.bind(this);
 }

  componentDidMount() {
   //retrieve products initially to check for SKU numbers
  productService.readAll().then(data => {
   this.setState({ products: data.items });
 });

  //checks if cookie is in session upon reload
  if(Cookies.get('jsonAuth') === '9p8hfdn3498f4') {
   this.setState({isLoggedIn: true});
  }
 }

    //the start of the API call sessions
  handleSubmit = (e) => {
  //try catch method to catch invalid JSON syntax
  try {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    this.state.po_id = Math.random().toString(36).substr(2, 5);

    const formattedTimestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("time stamp: " + formattedTimestamp);

    let order_json = {
      order: {
        order_info: {
          partner_order_id: this.state.po_id,
          username: "Printverse Pro",
          token: "i1TQ3UmnMgxwO5k",
          order_datetime: formattedTimestamp,
          source: "pvp.com",
          callback_url: `https://services.printversepro.com/order_info/${this.state.po_id}`
        },
        ship_from_info: {
          ship_from_company: "Photo USA",
          ship_from_fname: "Customer",
          ship_from_lname: "Service",
          ship_from_street1: "46595 Landing Pkwy",
          ship_from_street2: "",
          ship_from_street3: "",
          ship_from_city: "FREMONT",
          ship_from_stateprov: "CA",
          ship_from_country: "US",
          ship_from_country_name: "United States",
          ship_from_zip: "94538",
          ship_from_phone: "0000000000",
          ship_from_email: "support@printversepro.com"
        },
        ship_to_info: {
          ship_to_company: "",
          ship_to_fname: this.state.first_name,
          ship_to_lname: this.state.last_name,
          ship_to_street1: this.state.address1,
          ship_to_street2: this.state.address2 || "",
          ship_to_street3: "",
          ship_to_city: this.state.city,
          ship_to_stateprov: this.state.state,
          ship_to_country: this.state.country,
          ship_to_country_name: this.state.country_name,
          ship_to_zip: this.state.zip_code,
          ship_to_email: "support@printversepro.com",
          ship_to_method: "STANDARD",
          ship_to_code: "STANDARD"
        },
        order_items: {
          order_item: [
            {
            order_item_product_code: this.state.sku,
            order_item_partner_product_code: this.state.sku,
            order_item_quantity: this.state.quantity,
            order_item_image_url: this.state.artwork_url,
            order_item_image_ftp: ""
          }
        ]
        },
        order_attachments: {
          order_packlist: "DEFAULT",
          order_packlist_ftp: ""
        }
      }
    }    


  // let data = JSON.parse(this.state.formData.orderDetails.value);

   
   this.setState({orderData: order_json});
   let po = order_json.order.order_info.partner_order_id;
   let company_name = order_json.order.order_info.username;

    // userService.getIdByCompanyName(company_name).then(async res => {
      let userID = 1;

     companyService.getCompanyId(company_name).then(async res => {
        let opID = res.item.user_id;
        let company_id = res.item.company_id;
    
        
        //API call uploads to order_data table
        await orderData.sendOrderData(company_id, order_json, po).then(async res => {
        //call sends to test-orders OP
        console.log("result: " + res);
        console.log("JSON result: " + JSON.stringify(res));
        await opService.send_order(order_json).then(res => {
          let status = res.item.result.status;
          let message = res.item.result.message;
          let response = {
            status: res.item.result.status,
            message: res.item.result.message,
            order_id: res.item.result.order_id,
            partner_order_id: res.item.result.partner_order_id,
            reference_id: res.item.result.reference_id
          }
          //if OP fails throw error and do nothing
          if(status === "failure") {
            this.setState({isPosted: false})
            this.setState({opResponse: response})
            toast.error('' + message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true
          });
          } else {
      
            let opOrder_id = res.item.result.reference_id;
            let skuNum = order_json.order.order_items.order_item[0].order_item_product_code;
            this.search_sku(skuNum, opOrder_id, company_id, userID, opID);
            this.setState({isPosted: true});
            this.setState({opResponse: response})
          }
        }).catch(err => {
          console.log("ERROR Sending to openprint: " + err)
          toast.error('' + err, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true
          });
        })
        }).catch(err => {
        console.log("ERROR Sending to order_data table: " + err );
          
        this.setState({isPosted: false});
        toast.error('' + err, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true
         });
        }) 
    
    
        }).catch(err => {
          console.log(JSON.stringify(err));
        })
    

    // }).catch(err => {
    //   console.log(err)
    // })

 
  //catch invalid JSON syntax error
   } catch(error) {
      console.log(error);
      toast.error('' + error, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true
    });
   }
  };

  //Method initializes data to pass to the database
  search_sku = (sku_num, opOrder_id, companyID, userID, opID) => {
  //checks if the user entered a valid sku number
  let f_item = this.state.products.filter(item => item.sku === sku_num);

  //if f_item is empty return, (No sku number found)
  if(!f_item[0]) {
      return;
   } else {
   this.setState({temp_products: f_item[0]} , () => {

      let noSymbolPrice = this.state.temp_products.price.replace(/[^0-9.-]+/g,'');
      let usShip = this.state.temp_products.shipping_us.replace(/[^0-9.-]+/g,'');
      let intlShip = this.state.temp_products.shipping_intl.replace(/[^0-9.-]+/g,'');
      let addShip = this.state.temp_products.add_shipping.replace(/[^0-9.-]+/g,'');
        
      let totalTax = (this.state.orderData.order.order_items.order_item[0].order_item_quantity) * (noSymbolPrice * 0.11);
      let totalPrice = (noSymbolPrice * this.state.orderData.order.order_items.order_item[0].order_item_quantity) + totalTax;
    
  let order_item = {
    order_id: opOrder_id,
    sku: sku_num,
    quantity: this.state.orderData.order.order_items.order_item[0].order_item_quantity,
    tax: totalTax.toFixed(2),
    us_shipping: usShip,
    intl_shipping: intlShip,
    addl_shipping: addShip,
    total_shipping: 0,
    company_id: companyID,
    price: noSymbolPrice
  };  
    
  let label_item = {
    op_order_id: opOrder_id,
    tracking: null,
    carrier: null,
    po: this.state.orderData.order.order_info.partner_order_id,
    company_id: companyID
  };
    
  let orders_table = {
    op_order_id: opOrder_id,
    company_id: companyID,
    user_id: userID,
    op_id: opID, 
    po: this.state.orderData.order.order_info.partner_order_id,
    order_time: this.state.orderData.order.order_info.order_datetime,
    fname: this.state.orderData.order.ship_to_info.ship_to_fname,
    lname: this.state.orderData.order.ship_to_info.ship_to_lname,
    address1: this.state.orderData.order.ship_to_info.ship_to_street1,
    address2: this.state.orderData.order.ship_to_info.ship_to_street2,
    city: this.state.orderData.order.ship_to_info.ship_to_city,
    state: this.state.orderData.order.ship_to_info.ship_to_stateprov,
    zip: this.state.orderData.order.ship_to_info.ship_to_zip,
    country: this.state.orderData.order.ship_to_info.ship_to_country,
    country_name: this.state.orderData.order.ship_to_info.ship_to_country_name,
    total_price: totalPrice.toFixed(2),
    pay_status: "N/A",
    pay_type: "N/A",
    username: this.state.orderData.order.order_info.username,
    total_qty: this.state.orderData.order.order_items.order_item[0].order_item_quantity,
    total_shipping: 0,
    total_tax: totalTax.toFixed(2),
    items_price: noSymbolPrice,
    charge_id: null
  };
  //passes the initialized objects to this function
  //function makes 3 API calls 
  this.send_ordersDatabase(orders_table, order_item, label_item);
    })
   }
  }

  //receives objs and posts to orders, order_items, and labels
  send_ordersDatabase(orders_table, order_item, label_item) {
  //posts to orders table
    orderService.saveOPOrder(orders_table).then(async res => {
    //posts to order_items table
      await orderitemService.send_orderItem(order_item).then( async res => {
      //posts to labels table
        await labelsService.sendLabel(label_item).then(res => {
          if (this.state.isPosted) {
            toast.success('Order Submitted!', {
            position: "top-center",
            autoClose: 3250,
            hideProgressBar: false,
            closeOnClick: true
          });
        } 
        }).catch(err => {
          console.log(err);
          toast.error('' + err, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true
        });
        })
    }).catch(err => {
        console.log(err);
        toast.error('' + err, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true
      });
      })
  }).catch(err => {
      console.log(err);
      toast.error('' + err, {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true
    });
    })
 };

 onInputchange(event) {
  this.setState({
    [event.target.name]: event.target.value
  });
}

  convertPropsToFormData(props) {
  const order = props.formData && props.formData.id ? props.formData : {};
  const initializedOrder = { orderDetails: order.orderDetails || "" };

  let formData = {
  orderDetails: {
    originalValue: initializedOrder.orderDetails,
    value: initializedOrder.orderDetails,
    valid: true,
    validation: {}
   }
  };
 for (let fieldName in formData) {
    const field = formData[fieldName];
    field.valid = validationHelper.validate(field.value, field.validation);
  }
    return formData;
  };





  //onSubmit form for the authorization modal
  onSubmit = e => {  
  const username = this.user.value;
  const pw = this.password.value;

  this.setState({password: pw, user: username}, () => {
    let item = {
      user: this.state.user,
      password: this.state.password,
    };

    let log = {
      message: 'Authorized',
      username: this.state.user
    };

    let logtwo = {
      message: 'NOT Authorized',
      username: this.state.user
    };

    //This API call creates a user & hashed password and sends to db
    // jsonAuthService.createCred(item).then(res => {
    //     console.log(res);
    //     console.log("authorization credential created")
    // }).catch(err => {
    //     console.log (err);
    // })

  if(!Cookies.get('jsonAuth')) {
    this.setState({isLoggedIn: false});  

    //if there is no active cookie call the .login API call
    //API call sets a cookie and allows the user to submit JSON data
    if(!Cookies.get('jsonAuth')) {
      jsonAuthService.login(item).then(res => {
          this.setState({ isLoggedIn: true}, () => {

          //10 minute:  0.00694444
          //20 sec:    0.000231481
          //10 sec:   0.000115741
          Cookies.set('jsonAuth', '9p8hfdn3498f4', {expires: 0.00694444})

          jsonAuthService.logCred(log).then(res => {
          }).catch(err => {
            console.log(err);
          });

          toast.success('Authorized', {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true
          });

          if(Cookies.get('jsonAuth') !== '9p8hfdn3498f4') {
          this.setState({isLoggedIn: false});
          }

          this.setState({ loginError: false })
        });
      }).catch(err => {
          this.setState({ loginError: true })
          jsonAuthService.logCred(logtwo).then(res => {
          }).catch(err => {
            console.log(err);
          });

          console.log(err);
          toast.error('Invalid Login', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true
            });
        })
    }
  }

})
 }

  //onClick button for the user
  //checks to see if there is an active cookie session
  //calls handleSubmit() which is the start of the API calls proccess
  callOnHandle = async e => {
    if(Cookies.get('jsonAuth') !== '9p8hfdn3498f4') {
      this.setState({isLoggedIn: false});
    } else {

      this.handleSubmit();
      this.clearText();

    }
  }

  clearText = () => {
    this.setState({   
    opResponse: '',
    po_id: '',
    first_name: '',
    last_name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',
    country:'',
    country_name: '',
    sku: '',
    quantity:'',
    artwork_url: ''})
  }


  imgUploadHandler = (e) => {
    const file = e.target.files[0];
    if (file) { 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = event => {
        this.setState({fileData: event.target.result});
        this.setState({rawFile: file});
      };

    }
  }

  imgUploader = (imgData) => {
     let formData = new FormData();
     formData.append('file', this.state.rawFile, this.state.rawFile.name);
     formData.append('user_id', 2990)

     orderService.uploadAwsImg(formData).then(res => {
      console.log("Upload sucessful", res);
      this.setState({awsImgLink: res.url});
      
     }).catch(err => {
      console.log("Error uploading file: ", err);
     })

  }
  clearArtwork = () => {
    console.log("Cleared")
    this.setState({awsImgLink: ''});
    this.setState({fileData: ''});
    this.setState({rawFile: ''});
  }

  render() {
  let panel_four = {
    width: '45%',
    marginLeft: '3rem'
  }

  return(
    <React.Fragment>
      <div className='op-container-wrapper'>
      <div className="panel panel-default" style={panel_four}>
        <div className="panel-heading" id="headingOne">
          <h3 className="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
            OpenPrint Manual Order Entry Form
            </a>
          </h3>
        </div>
        <div id="collapseFour" className="panel-collapse collapse in" role="tabpanel">
        <div className='order-form'>
          <label>First Name: </label> <input type="text" placeholder='Fname' name="first_name" value={this.state.first_name} onChange={this.onInputchange}/>
          <label>Last Name: </label> <input type="text" placeholder='Lname' name="last_name" value={this.state.last_name} onChange={this.onInputchange} />
          <label>Address 1: </label> <input type="text" placeholder='1234 street..'name='address1' value={this.state.address1} onChange={this.onInputchange}/>
          <label>Address 2: </label> <input type="text" placeholder='Apt 100 (Optional)' name='address2' value={this.state.address2} onChange={this.onInputchange}/>
          <label>City: </label> <input type="text" placeholder='City' name='city' value={this.state.city} onChange={this.onInputchange}/>
          <label>State: </label> <input type="text" placeholder='State "CA" 2 Letter code' name='state' value={this.state.state} onChange={this.onInputchange}/>
          <label>Zip: </label> <input type="text" placeholder='Zip' name='zip_code' value={this.state.zip_code} onChange={this.onInputchange}/>
          <label>Country: </label> <input type="text" placeholder='Country "US" 2 Letter code' name='country' value={this.state.country} onChange={this.onInputchange}/>
          <label>Country Name: </label> <input type="text" placeholder='Country Name  "United States.." ' name='country_name' value={this.state.country_name} onChange={this.onInputchange}/>
          <label>SKU: </label> <input type="text" placeholder='SKU' name='sku' value={this.state.sku} onChange={this.onInputchange}/>
          <label>Quantity: </label> <input type="text" placeholder='Quantity' name='quantity' value={this.state.quantity} onChange={this.onInputchange}/>
          <label>Artwork URL: </label> <input type="url" placeholder='Artwork URL' name='artwork_url' value={this.state.artwork_url} onChange={this.onInputchange}/>
        </div>
          <div className="panel-footer">
            <div className="jsonForm">
              <React.Fragment> {this.state.isLoggedIn === true ? ( <div> <button type="button" class="btn btn-info btn-lg" onClick={this.callOnHandle}>Submit</button>
              <div class="alert alert-success" role="alert">Authorized</div> </div>) : (<React.Fragment> 
              <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#myModal">Authorize</button>
              <div className="">
                {this.state.loginError ? <div class="alert alert-danger" role="alert"> ERROR: Authorization Failed </div> : null}
                <form>
                  <div class="modal fade" id="myModal" role="dialog">
                    <div class="modal-card" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="close" data-dismiss="modal">&times;</button>
                          <h4 class="modal-title">Authorization Form: </h4>
                        </div>
                        <div class="modal-body">
                          <h5>User: </h5>
                          <input 
                          type="text"
                          placeholder="User"
                          ref={input => this.user = input}
                          />
                          <h5>Password: </h5>
                          <input 
                          type="password"
                          placeholder="Password"
                          ref={input => this.password = input}
                          />
                        </div>
                        <div class="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={this.onSubmit} data-dismiss="modal">Submit</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </React.Fragment>)}
            </React.Fragment>
  
            </div>
          </div>
          <div>
            <div> 
              {this.state.isPosted === true ? (
              <div style= {{color: 'green', padding: '8px'}}> 
                <h5>Status: {this.state.opResponse.status}</h5>
                <h5>Message: {this.state.opResponse.message}</h5> 
                <h5>Order_Id: {this.state.opResponse.order_id}</h5>
                <h5>Partner_order_Id: {this.state.opResponse.partner_order_id}</h5>
                <h5>Reference_Id: {this.state.opResponse.reference_id}</h5>
              </div>
              ) : this.state.isPosted === false ? (
                <div style= {{color: 'red', padding: '8px'}}> 
                      <h5>Status: {this.state.opResponse.status}</h5>
                      <h5>Message: {this.state.opResponse.message}</h5> 
                  </div>) : (<div></div>) } 
            </div>
          </div>
        </div>
    </div>

      <div className='panel img-upload-wrapper'>
        <label>Upload Image Function</label>
        <p>If you have a downloaded image, store it on the server and provide a live link for OP to process.<br/> <br/> Use this Function to upload an img then paste the AWS S3 URL onto the artwork URL field.</p>
        <input type="file" onChange={this.imgUploadHandler} />
        {this.state.fileData ?  <div> <img style={{height:'100px', width:'100px'}}  src={this.state.fileData} /> <button style={{margin:'15px'}} onClick={()=>this.imgUploader(this.state.fileData)}>Upload Image</button> </div> : <div> </div> } 
        {this.state.awsImgLink ? <div>  <p>{this.state.awsImgLink}</p> <button onClick={() => {navigator.clipboard.writeText(this.state.awsImgLink)}}>Copy URL</button></div> : <div> </div>}
        <button style={{margin:'15px'}} onClick={()=>this.clearArtwork()}>Clear Art</button>
      </div>
      </div>
    
    </React.Fragment>
  );
  }
}

export default OrdersForm;