import axiosInstance from "../config/axios.config";
import * as env from "../config/env"
const headers = {};


const baseUrl = `${env.url}/api/admins`

export function getById(id) {
    const config = {
        method: "GET",
        headers
    };
    return axiosInstance(baseUrl + "/" + id, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function create(usersData) {
    const config = {
        method: "POST",
        headers,
        data: usersData
    };
    return axiosInstance(baseUrl, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function logout() {
    const config = {
        method: "POST",
        headers,
    };
    return axiosInstance(baseUrl + "/logout", config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function readAll() {
    const config = {
        method: "GET",
        headers
    };
    return axiosInstance
        .get(baseUrl, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function update(usersData) {
    const config = {
        method: "PUT",
        headers,
        data: usersData
    };
    return axiosInstance(`${baseUrl}/${usersData._id}`, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function del(id) {
    const config = {
        method: "DELETE",
        headers
    };
    return axiosInstance(`${baseUrl}/${id}`, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function login(usersData) {
    const config = {
        method: "POST",
        data: usersData,
    };
    return axiosInstance(baseUrl + "/login", config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function register(usersData) {
    const config = {
        method: "POST",
        headers,
        data: usersData
    };
    return axiosInstance(baseUrl + `/register`, config)

        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function resetPassword(usersData) {
    const config = {
        method: "PUT",
        data: usersData
    };
    return axiosInstance(baseUrl + "/reset-password", config)
        .then(data => {
            return responseSuccessHandler(data);
        })
        .catch(data => {
            return responseErrorHandler(data);
        });
}

export function getProfile() {
    if (!localStorage.getItem("adminProfile")) {
        const config = {
            method: "GET",
            headers
        };
        return axiosInstance(`${baseUrl}/get-profile`, config)
            .then(responseSuccessHandler)
            .then(response => {
                localStorage.setItem("adminProfile", JSON.stringify(response.item));
                return response.item;
            })
            .then(response => {
                return Promise.resolve(JSON.parse(localStorage.getItem("adminProfile")));
            })
            .catch(responseErrorHandler);
    } else {
        return Promise.resolve(JSON.parse(localStorage.getItem("adminProfile")));
    }
}

const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = error => {
    console.log(error);
    return Promise.reject(error);
};
