import React from "react";
import { Route, Switch} from "react-router-dom";
import NoLayoutRouter from "../components/NoLayoutRouter"
import LayoutRouter from "../components/LayoutRouter"


export default function Router() {
    return (
        <React.Fragment>
            <Switch>
                <Route path="/auth" component={NoLayoutRouter} />
                <Route path="/" component={LayoutRouter} />
            </Switch>

        </React.Fragment>
    );
}