import React, { Component } from "react";
import "./css/App.css";
// import createHistory from "history/createBrowserHistory";
import { createBrowserHistory } from "history";



import { Router } from "react-router-dom";


import Layout from "./constants/Layout"
import { ToastContainer } from 'react-toastify';


//const history = createHistory();
const history = createBrowserHistory();

class App extends Component {
  render() {
    return (
      <React.Fragment>

        <Router history={history}>
          <Layout/>
          <ToastContainer pauseOnFocusLoss={false} />
        </Router >
      </React.Fragment>

    );
  }
}

export default App;