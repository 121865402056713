import axiosInstance from "../config/axios.config";
import axios from "axios";
import * as env from "../config/env"
const headers = {};

const baseUrl = `${env.url}/api/labels`
const railsUrl = `${env.railsUrl}`

export function readOrderStatus() {
    const config = {
        method: "GET",
        headers
    };
    return axiosInstance
        .get(`${baseUrl}/status`, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function getOrderLabel(po) {
    const config = {
        method: "GET",
        headers,
        data: po
    }
    return axiosInstance
        .get(`${baseUrl}/`, config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
}

export function sendLabel(labelOrder) {
    const config = {
        method: "POST",
        headers,
        data:  {
            "op_order_id": labelOrder.op_order_id,
            "carrier": labelOrder.carrier,
            "company_id": labelOrder.company_id,
            "po": labelOrder.po
        }
    }
    return axios(`${railsUrl}/tracking`, config).then(responseSuccessHandler).catch(responseErrorHandler)
}

const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = error => {
    console.log(error);
    return Promise.reject(error);
};