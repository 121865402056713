const carriers = [
    {
        name: "STANDARD",
        methods: ['STANDARD']
    },
    {
        name: "USPS",
        methods: ["First", "Priority", "Express"]
    },
    {
        name: "UPS",
        methods: ["01-Next Day Air", "02-2nd Day Air", "03-Ground", "07-Express", "08-Expedited", "11-UPS Standard", "12-3 Day Select", "13-Next Day Air Saver", "14-Next Day Air Early AM", "54-Express Plus", "59-2nd Day Air AM"]
    },
    {
        name: "FedEx",
        methods: ["FEDEX_1_DAY_FREIGHT", "FEDEX_2_DAY FEDEX_2_DAY_AM", "FEDEX_2_DAY_FREIGHT", "FEDEX_EXPRESS_SAVER", "FEDEX_FIRST_FREIGHT", "FEDEX_FREIGHT_ECONOMY", "FEDEX_FREIGHT_PRIORITY", "FEDEX_GROUND FIRST_OVERNIGHT", "GROUND_HOME_DELIVERY", "PRIORITY_OVERNIGHT", "STANDARD_OVERNIGHT"]
    }
]


export default carriers