import React from 'react';
import { Redirect } from 'react-router-dom';
import * as userService from '../services/users.service';
import * as adminServices from '../services/admin.service';
import * as productService from '../services/product.service';
import * as stripeService from '../services/stripe.service';
import * as openprintService from '../services/openprint.service';
import * as tokenService from '../services/token.service';
import * as orderdataService from '../services/orderdata.service';
import * as orderService from '../services/orders.service';
import * as labelsService from '../services/labels.service';
import * as heartbeatService from '../services/heartbeat.service';
import * as openPrint from '../helpers/openprint.helper';
import * as pvp from '../helpers/printverse.helper';
import * as labels from '../helpers/labels.helper';

import { Link } from 'react-router-dom';
import '../css/modal.css';
import carriers from '../lists/ship_carriers';
import countries from '../lists/countries';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Nav extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            customers: [],
            formData: {
                user: '',
                fName: '',
                lName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                country: '',
                countryName: '',
                zip: '',
                carrier: '',
                method: '',
                product: [],
                imageUrl: [],
                orderTotal: '',
                quantities: []
            },
            stripeId: '',
            products: [],
            shipMethods: [],
            countries: countries,
            adminRole: 'off',
            logoutSuccessful: false,
            nodeOk: true,
            railsOk: true,
            nodeDbOk: true,
            railsDbOk: true,
            isAdmin: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.createSampleOrder = this.createSampleOrder.bind(this);
        this.logout = this.logout.bind(this);
        this.submitOrder = this.submitOrder.bind(this);
        this.checkNodeServer = this.checkNodeServer.bind(this);
        this.checkRailsServer = this.checkRailsServer.bind(this);
        this.produceNodeServer = this.produceNodeServer.bind(this);
        this.produceRailsServer = this.produceRailsServer.bind(this);
        this.produceNodeTests = this.produceNodeTests.bind(this);
        this.produceRailsTests = this.produceRailsTests.bind(this);
    }

    componentWillMount() {
        const isauthCookieAvailable = document.cookie
            .split(';')
            .filter((item) => {
                return item.includes('auth');
            }).length;

        if (!isauthCookieAvailable) {
            window.location = '/auth/login';
            //   this.props.history.push("/auth/login");
            this.redirectInProgress = true;
        }
    }

    componentDidMount() {
        if (this.redirectInProgress) return;

        adminServices
            .getProfile()
            .then((data) => {
                this.setState({ adminRole: data.role });
            })
            .catch((err) => {
                console.log('error: ' + JSON.stringify(err));
                this.setState({ adminRole: 'No user' });
            });
    }

    handleChange(event) {
        let value = event.target.value;
        let name = event.target.name;

        if (name === '') {
            const formClone = { ...this.state.formData };
            this.setState({ formData: formClone });
        }
        // let isCarrier = value.indexOf('-')
        if (value.split('-')[0] === 'ship') {
            let splitCarrier = value.split('-')[1];
            const formClone = { ...this.state.formData };
            const currMethods = this.setShipMethods(splitCarrier);
            formClone[event.target.name] = event.target.value;
            this.setState({ formData: formClone, shipMethods: currMethods });
        } else {
            const formClone = { ...this.state.formData };
            formClone[event.target.name] = event.target.value;
            this.setState({ formData: formClone });
        }
    }

    setShipMethods = (carrier) => {
        let ship_methods;
        let the;
        the = carriers.filter((item, i) => {
            return item.name === carrier;
        });
        ship_methods = the[0].methods;
        return ship_methods;
    };

    saveOpOrderInPvp = (opOrder, payStatus, userId) => {
        let pvpOrder = pvp.generatePvpOrder(opOrder, payStatus);
        orderService.saveOPOrder(pvpOrder).then((response) => {
            let row_id = response.order.id;
            if (response.result === 'success!') {
                this.saveOrderLabel(opOrder, row_id, userId);
            }
        });
    };

    updateOpOrderInPvp = (opOrder, payStatus, row_id, op_id) => {
        let pvpOrder = pvp.generatePvpOrder(opOrder, payStatus);

        pvpOrder.op_id = op_id;
        delete pvpOrder.ship_to_company;

        orderService.update(pvpOrder, row_id).then((response) => {
            if (response.isSuccessful) {
                this.hideModal();
            }
        });
    };

    saveOrderLabel = (opOrder, row_id, userId) => {
        let labelOrder = labels.generateLabelOrder(opOrder);
        labelsService.sendLabel(labelOrder).then((response) => {
            if (response.result === 'success') {
                this.getStripeId(opOrder, opOrder.total, userId, row_id);
                //this.saveOrderItems(opOrder)
            }
        });
    };

    // saveOrderItems = (opOrder) => {
    //     //let orderItems = generateOrderItems(opOrder);
    //     orderItemsService.sendOrderItems(orderItems).then(response => {
    //         if (response.result === 'success!') {
    //             this.getStripeId(opOrder, opOrder.total, opOrder.userId);
    //         }
    //     })
    // }

    getStripeId(opOrder, total, opUserId, row_id) {
        stripeService
            .getStripeIdByOpUserId(opUserId)
            .then((data) => {
                if (data.item.length > 0) {
                    this.setState({ stripeId: data.item }, () => {
                        let description = `Company: ${opOrder.order.order_info.username},`;
                        let my_products = this.state.formData.product;
                        let my_quantities = this.state.formData.quantities;
                        for (let i = 0; i < my_products.length; i++) {
                            if (i == my_products.length - 1) {
                                description += `sku: ${my_products[i]}(${my_quantities[i]}); `;
                            } else {
                                description += `sku: ${my_products[i]}(${my_quantities[i]}), `;
                            }
                        }
                        description += `op_order_id: ${opOrder.orderId}`;

                        let stripeData = {
                            total: total,
                            stripe_id: this.state.stripeId,
                            description: description.toString()
                        };

                        stripeService
                            .processPayment(stripeData)
                            .then((data) => {
                                if (data.message === 'success') {
                                    toast.info(
                                        'Customer was charged: $' + total,
                                        {
                                            position: 'top-right',
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined
                                        }
                                    );
                                    this.updateOpOrderInPvp(
                                        opOrder,
                                        'Paid',
                                        row_id,
                                        opUserId
                                    );
                                } else {
                                    toast.error('Error: ', {
                                        position: 'top-right',
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined
                                    });
                                }
                            })
                            .catch((err) => {
                                toast.error(
                                    'There was an error with payment.',
                                    {
                                        position: 'top-right',
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined
                                    }
                                );
                            });
                    });
                }
            })
            .catch((error) => {
                toast.error('There was an error with payment: ' + error, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            });
    }

    createOpOrder = (order, userId) => {
        let opOrder = openPrint.generateOrder(order);
        if (typeof opOrder !== 'undefined') {
            let po = opOrder.order.order_info.partner_order_id;
            orderService.readOrderJson(po).then((response) => {
                if (
                    response.isSuccessful &&
                    response.item === 'Order Data Error'
                ) {
                    orderdataService
                        .sendOrderData(order.companyId, opOrder, po)
                        .then((response) => {
                            if (response.result === 'success!') {
                                this.send_op_order(order, opOrder, userId);
                            } else if (response.result === 'failure') {
                                toast.error('Error: ' + response.result, {
                                    position: 'top-right',
                                    autoClose: 4000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined
                                });
                            }
                        });
                } else if (!response.isSuccessful) {
                    toast.error('Error: ' + response.alert.message, {
                        position: 'top-right',
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                } else {
                    this.send_op_order(order, opOrder, userId);
                }
            });
        }
    };

    send_op_order = (orig_order, opOrder, userId) => {
        openprintService.send_order(opOrder).then((response) => {
            if (response.item.result.status === 'success') {
                let orderId = response.item.result.reference_id
                    ? response.item.result.reference_id
                    : response.item.result.order_id;
                toast.info(`Order was accepted by OpenPrint: ${orderId}`, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                opOrder.orderId = orderId;
                opOrder.po = response.item.result.partner_order_id;
                opOrder.companyId = orig_order.companyId;
                opOrder.total = orig_order.total;
                opOrder.userId = orig_order.userId;

                this.saveOpOrderInPvp(opOrder, 'UnPaid', userId);
            } else if (response.item.result.status === 'failure') {
                toast.error('Error: ' + response.item.result.message, {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        });
    };

    getUserToken = (userId) => {
        tokenService
            .getTokenByOpUserId(userId)
            .then((data) => {
                if (data.item.length > 0) {
                    let order = {
                        token: data.item,
                        userId: this.state.formData.user.split('-')[3],
                        company: this.state.formData.user.split('-')[1],
                        companyId: this.state.formData.user.split('-')[2],
                        firstName: this.state.formData.fName,
                        lastName: this.state.formData.lName,
                        address1: this.state.formData.address1,
                        address2: this.state.formData.address2,
                        city: this.state.formData.city,
                        state: this.state.formData.state,
                        countryCode: this.state.formData.country.split('-')[0],
                        country: this.state.formData.country.split('-')[1],
                        zip: this.state.formData.zip,
                        shippingCarrier:
                            this.state.formData.carrier.split('-')[1],
                        shippingMethod: (this.state.formData.method =
                            this.state.formData.method.indexOf('-') > -1
                                ? this.state.formData.method.split('-')[0]
                                : this.state.formData.method),
                        sku: this.state.formData.product,
                        imageUrl: this.state.formData.imageUrl,
                        quantities: this.state.formData.quantities,
                        total: this.state.formData.orderTotal,
                        payType: 'Stripe'
                    };

                    this.createOpOrder(order, userId);
                } else {
                }
            })
            .catch((err) => {
                toast.error(
                    'Error: Order Failed. API ' +
                        err.response.data.errors +
                        '.',
                    {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    }
                );
            });
    };

    submitOrder = (event) => {
        event.preventDefault();
        let opUserId = this.state.formData.user.split('-')[0];
        this.getUserToken(opUserId);
    };

    logout = () => {
        adminServices.logout().then((response) => {
            localStorage.clear();
            this.setState({ logoutSuccessful: response.isSuccessful });
        });
    };

    checkNodeServer = () => {
        setInterval(
            function () {
                heartbeatService
                    .getNodeStatus()
                    .then((response) => {
                        if (
                            response.status === '202' &&
                            response.data.alert.message === 'DbNotOk'
                        ) {
                            this.setState({ nodeDbOk: false });
                        } else {
                            this.setState({ nodeOk: true });
                            this.setState({ nodeDbOk: true });
                        }
                    })
                    .catch((err) => {
                        console.log('error: ' + JSON.stringify(err));
                        this.setState({
                            nodeDbOk: false,
                            nodeOk: false
                        });
                    });
            }.bind(this),
            915000
        );
    };

    checkRailsServer = () => {
        setInterval(
            function () {
                heartbeatService
                    .getRailsStatus()
                    .then((response) => {
                        try {
                            if (response.status === 200) {
                                this.setState({
                                    railsDbOk: true,
                                    railsOk: true
                                });
                            } else {
                                this.setState({
                                    railsDbOk: false
                                });
                            }
                        } catch (error) {
                            console.log('error: ' + JSON.stringify(error));
                            this.setState({
                                railsDbOk: false
                            });
                        }
                        return;
                    })
                    .catch((err) => {
                        console.log('error: ' + JSON.stringify(err));
                        this.setState({
                            railsDbOk: false,
                            railsOk: false
                        });
                    });
            }.bind(this),
            900000
        );
    };

    produceNodeServer = () => {
        if (this.state.nodeDbOk && this.state.nodeOk) {
            return (
                <button
                    className="btn btn-success dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Node Server
                </button>
            );
        }
        return (
            <button
                className="btn btn-danger dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                Node Server
            </button>
        );
    };

    produceRailsServer = () => {
        if (this.state.railsDbOk && this.state.railsOk) {
            return (
                <button
                    className="btn btn-success dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    Rails Server
                </button>
            );
        }
        return (
            <button
                className="btn btn-danger dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                Rails Server
            </button>
        );
    };

    produceNodeTests = () => {
        if (this.state.nodeDbOk && this.state.nodeOk) {
            return (
                <ul className="dropdown-menu">
                    <li className="text-center"> API Endpoint OK</li>
                    <li className="text-center"> Database OK</li>
                    <li className="text-center"> 2/2 Tests Passed</li>
                </ul>
            );
        } else if (!this.state.nodeDbOk && !this.state.nodeOk) {
            return (
                <ul className="dropdown-menu">
                    <li className="text-center"> API Endpoint Fail</li>
                    <li className="text-center"> Database Fail</li>
                    <li className="text-center"> 0/2 Tests Passed</li>
                </ul>
            );
        } else if (!this.state.nodeDbOk) {
            return (
                <ul className="dropdown-menu">
                    <li className="text-center"> API Endpoint OK</li>
                    <li className="text-center"> Database Fail</li>
                    <li className="text-center"> 1/2 Tests Passed</li>
                </ul>
            );
        }
    };

    produceRailsTests = () => {
        if (this.state.railsDbOk && this.state.railsOk) {
            return (
                <ul className="dropdown-menu">
                    <li className="text-center"> API Endpoint OK</li>
                    <li className="text-center"> Database OK</li>
                    <li className="text-center"> 2/2 Tests Passed</li>
                </ul>
            );
        } else if (!this.state.railsDbOk && !this.state.railsOk) {
            return (
                <ul className="dropdown-menu">
                    <li className="text-center"> API Endpoint Fail</li>
                    <li className="text-center"> Database Fail</li>
                    <li className="text-center"> 0/2 Tests Passed</li>
                </ul>
            );
        } else if (!this.state.railsDbOk) {
            return (
                <ul className="dropdown-menu">
                    <li className="text-center"> API Endpoint OK</li>
                    <li className="text-center"> Database Fail</li>
                    <li className="text-center"> 1/2 Tests Passed</li>
                </ul>
            );
        }
    };

    createSampleOrder = (event) => {
        event.preventDefault();
        userService.readAll().then((data) => {
            this.setState({ customers: data.items }, () => {
                // this.showModal()
            });
        });
        productService.readAll().then((data) => {
            this.setState({ products: data.items });
            this.showModal();
        });
    };

    showModal = (id) => {
        this.setState({ showModal: true });
    };

    hideModal = () => {
        const formData = { ...this.state.formData };
        const keys = Object.keys(formData);
        for (let i of keys) {
            keys[i] = '';
        }
        this.setState({
            showModal: false,
            formData: keys
        });
        setTimeout(function () {
            window.location.reload(false);
        }, 2000);
    };

    render() {
        if (this.redirectInProgress) return null;

        const nav2 = {
            marginTop: '-0.5em'
        };
        return (
            <React.Fragment>
                {/* {this.state.showModal && this.state.customers && <SampleOrderModal handleClose={this.hideModal} customers={this.state.customers} onChange={this.handleChange} handleOpOrder={this.submitOrder} formData={this.state.formData} products={this.state.products} shipMethods={this.state.shipMethods} countries={this.state.countries}/>} */}

                <nav className="navbar navbar-inverse">
                    {this.state.logoutSuccessful && (
                        <Redirect to="/auth/login" />
                    )}
                    {(this.state.adminRole === 'User' ||
                        this.state.adminRole === 'Admin') && (
                        <React.Fragment>
                            {' '}
                            <button
                                onClick={this.logout}
                                type="button"
                                className="btn btn-default pull-right"
                            >
                                Logout
                            </button>
                            {this.checkNodeServer()}
                            {this.checkRailsServer()}
                            <div className="pull-left btn-group">
                                {this.produceNodeServer()}
                                <div className="divider"></div>
                                {this.produceNodeTests()}
                            </div>
                            <div className="pull-left btn-group">
                                {this.produceRailsServer()}
                                <div className="divider"></div>
                                {this.produceRailsTests()}
                            </div>
                        </React.Fragment>
                    )}

                    {this.state.adminRole === 'Admin' && (
                        <React.Fragment>
                            <div className="pull-right btn-group">
                                <button
                                    className="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    Create User
                                </button>
                                <div className="divider"></div>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to="/createuser">
                                            Create User
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </React.Fragment>
                    )}
                </nav>
                <div className="containerr" style={nav2}>
                    <ul className="nav nav-tabs nav-justified">
                        <li role="presentation">
                            <Link to="/stats" className="nav-tab">
                                <span>Stats</span>
                            </Link>
                        </li>
                        <li role="presentation">
                            <Link to="/orders" className="nav-tab">
                                <span>Orders</span>
                            </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                            <Link to="/products" className="nav-tab">
                                Products
                            </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                            <Link to="/users" className="nav-tab">
                                Users
                            </Link>
                        </li>

                        {/* <li role="presentation" >
                            <Link to="/admin" className="nav-tab">
                       
                                <span>Admin</span>
                        
                            </Link>
                        </li> */}
                        <li role="presentation">
                            <Link to="/errors" className="nav-tab">
                                <span>Errors</span>
                            </Link>
                        </li>
                        <li role="presentation">
                            <Link to="/companies" className="nav-tab">
                                <span>Companies</span>
                            </Link>
                        </li>
                        {/* <li role="presentation" >
                            <Link to="/stripe" className="nav-link">
                       
                                <span>Stripe Customers</span>
                        
                            </Link>
                        </li> */}
                        {/* <li role="presentation" >
                            <Link to="/shippings" className="nav-tab">
                       
                                <span>Shippings</span>
                        
                            </Link>
                        </li> */}
                        <li role="presentation">
                            <Link to="/promos" className="nav-tab">
                                <span>Promos</span>
                            </Link>
                        </li>
                        <li role="presentation">
                            <Link to="/op-submissions" className="nav-tab">
                                <span>OP Submissions</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

export default Nav;
