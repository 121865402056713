import axiosInstance from "../config/axios.config";
import * as env from "../config/env"
const headers = {
    "Content-Type": "application/json",
};

const baseUrl = `${env.url}/api/jsonauth`


export function createCred(userData) {
    const config ={
        method: "POST",
        headers,
        data: userData
    };
    return axiosInstance(baseUrl + "/create_jsoncred", config)
        .then(responseSuccessHandler)
        .catch(responseErrorHandler);
};

export function login(userData) {
    const config = {
        method: "POST",
        headers,
        data: userData
    };
    return axiosInstance(baseUrl + "/login_jsoncred", config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler);
};

export function logCred(userData) {
    const config = {
        method: "POST",
        headers,
        data: userData
    };
    return axiosInstance(baseUrl + "/log_cred", config)
    .then(responseSuccessHandler)
    .catch(responseErrorHandler);
};

const responseSuccessHandler = response => {
    return response.data;
};

const responseErrorHandler = error => {
    console.log(error);
    return Promise.reject(error);
};
