import React from "react";
import * as orderService from "../services/orders.service";
import * as userService from "../services/users.service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from 'react-csv';
import "../orders.css";
import NewOrderForm from "./NewOrderForm";
import OrdersForm from "./OrdersForm";
import Modal from './Modal'
const moment = require("moment")


class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            order_items: [],
            date_begin: '',
            date_end: '',
            customers: [],
            selectedCustomer: "",
            pvpId: "",
            companyId: "",
            opId: "",
            showModal: false,
            order_json: [],
            curr_order: "",
            statusIsUpdated: false,
            allOrders: [],
            shippedOrders: [],
            disabledOrders: [],
            lateOrders: [],
            refundedOrders: []
        };
        this.csvLink = React.createRef();


        this.onCancel = this.onCancel.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSelectCustomer = this.onSelectCustomer.bind(this);
        this.viewOrder = this.viewOrder.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.getOrderStatuses = this.getOrderStatuses.bind(this);
        this.filterChange = this.filterChange.bind(this);

    }

    componentDidMount() {
        orderService.allOrderData().then(data1 => {
            let arr
            arr = data1.items.filter(function (item) {
                const newDate = moment(item['order_time'], "YYYY-MM-DDTHH:mm:ss.fff Z")
                const time = moment(newDate).unix()
                const six_months_ago = moment().subtract(3, "months").unix()
                return time > six_months_ago
            })

            arr = arr.sort(function (a, b) {
                return parseInt(b.id) - parseInt(a.id)
            })

            this.setState({ 
                statusIsUpdated: true,
                orders: arr,
                allOrders: arr 
            });

            let shipped = arr.filter((order) => {
                return order.status === 'Shipped';
            });
            let disabled = arr.filter((order) => {
                return order.status === 'Disabled';
            });
            let late = arr.filter((order) => {
                return order.isLate;
            });
            let refunded = arr.filter((order) => {
                return order.pay_status !== 'Paid';
            });

            this.setState({
                shippedOrders: shipped,
                disabledOrders: disabled,
                lateOrders: late,
                refundedOrders: refunded
            });
        })

        userService.readAll().then(data => {
            this.setState({ customers: data.items }, () => {
            })
        })

    }

    getOrderStatuses() {
        orderService.readOrderStatus().then(response => {
            if (response.items == "success") {
                this.setState({ statusIsUpdated: true })

            }
        })
    }

    onSelectCustomer(item, idData) {
        this.setState({
            selectedCustomer: item,
            pvpId: idData.pvp_id,
            companyId: idData.company_id,
            opId: idData.op_user_id

        })
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }



    getOrderItems(date_begin, date_end) {
        orderService.allOrderItems(date_begin, date_end).then(data => {
            let orderItems = [];
            if (data.items.length === 0) {
                alert('No orders found')
            }
            else if (data.items.length === 1) {
                orderItems.push(data.items[0])
                this.setState({ order_items: orderItems });
                this.csvLink.current.link.click();
            } else {
                for (let i = 0; i < data.items.length - 1; i++) {
                    if (data.items[i].order_id !== data.items[i + 1].order_id) {
                        orderItems.push(data.items[i])
                        orderItems.push({})
                    } else {
                        orderItems.push(data.items[i])
                    }
                }
                orderItems.push(data.items[data.items.length - 1])
                this.setState({ order_items: orderItems });
                this.csvLink.current.link.click();
            }


            // if(orderItems.length == 0) {
            //     orderItems.push({})
            //     this.setState({ order_items: orderItems })
            // } 
            // else {
            //     this.setState({ order_items: orderItems }, () => {
            //         console.log(this.state.order_items)
            //         //this.csvLink.current.link.click();
            //     })
            // }
        })
    }


    onCancel() {

        this.setState({ formData: null, selectedCustomer: "", pvpId: "", companyId: "", opId: "" });
    }

    onSave(updatedFormData) {
        this.setState(prevState => {
            const existingItem = prevState.orders.filter(item => {
                return item.id === updatedFormData.id;
            });
            let updatedItems = [];
            if (existingItem && existingItem.length > 0) {
                updatedItems = prevState.orders.map(item => {
                    return item.id === updatedFormData.id ? updatedFormData : item;
                });
            } else {
                updatedItems = prevState.orders.concat(updatedFormData);
            }
            return {
                orders: updatedItems,
                formData: null,
                errorMessage: null
            };
        });
    }

    viewOrder = (order) => {
        let po_id = order.po
        orderService.readOrderJson(po_id).then(response => {
            this.setState({ showModal: true, order_json: response.item, curr_order: po_id })

        }).catch(err => {
            if (err) {
                toast.error("Error: No Order Data Found ", {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        })


    }

    hideModal = () => {
        this.setState({
            showModal: false,
            order_data: {}
        })
    }

    onSelect(item, event) {
        event.preventDefault();
        let matchedCustomer = this.state.customers.filter(value => {
            return value.company_id === item.company_id

        })
        this.setState({
            formData: item,
            selectedCustomer: matchedCustomer[0].company_id,
            pvpId: matchedCustomer[0].id,
            companyId: matchedCustomer[0].company_id,
            opId: matchedCustomer[0].user_id
        }, function () {
        });
    }

    filterChange = (e) => {
        let filter = e.target.value;
        if (filter === 'SHIPPED') {
            this.setState({
                orders: this.state.shippedOrders
            });
        } else if (filter === 'DISABLED') {
            this.setState({
                orders: this.state.disabledOrders
            });
        } else if (filter === 'LATE') {
            this.setState({
                orders: this.state.lateOrders
            });
        } else if (filter === 'REFUNDED') {
            this.setState({
                orders: this.state.refundedOrders
            });
        } else {
            this.setState({
                orders: this.state.allOrders
            });
        }
    }


    render() {
        const orders = this.state.statusIsUpdated && this.state.orders ? (
            this.state.orders.map((order, i) => (
                <tr className="notFirst" key={i} onClick={this.onSelect.bind(this, order)}>
                    <td>{`${order.id}`}</td>
                    <td>{`${order.email}`}</td>
                    <td>{`${order.op_order_id}`}</td>
                    <td>{`${order.company_id}`}</td>
                    <td>{`${order.company_name}`}</td>
                    {/* <td>{`${order.confirmation ? order.confirmation.toUpperCase() : '-'}`}</td> */}
                    <td>{`${order.po}`}</td>
                    <td>{`$${order.total_price}`}</td>
                    <td>{`${order.pay_type}`}</td>
                    <td><div className={order.pay_status === 'Paid' ? 'Badge box-background--green100 text-center' : 'Badge box-background--grey text-center'}><span className={order.pay_status === 'Paid' ? 'Text-color--green600' : 'Text-color--grey'}>{`${order.pay_status === 'Paid' ? 'Paid' : 'Refunded'}`}<svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-check2-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                        <path fillRule="evenodd" d="M8 2.5A5.5 5.5 0 1 0 13.5 8a.5.5 0 0 1 1 0 6.5 6.5 0 1 1-3.25-5.63.5.5 0 1 1-.5.865A5.472 5.472 0 0 0 8 2.5z" />
                    </svg></span></div></td>
                    <td>{`${new Date(order.order_time).toLocaleDateString("en-US")} ${new Date(order.order_time).toLocaleTimeString("en-US")}`}</td>
                    <td><button type="button" className="btn btn-default" onClick={() => this.viewOrder(order)}>View</button></td>
                    <td>{`${order.status}`}</td>
                    <td><div className={order.isLate ? 'Badge box-background--red100 text-center' : "Badge box-background--green100 text-center"}><span className={order.isLate ? 'text-color--red600' : ""}><strong>{`${order.isLate ? "LATE!" : "OK"}`}</strong></span></div></td>

                    {/* <td>{`${order.tracking}`}</td> */}
                    {/* <td>{`${new Date(order.created_at).toLocaleDateString("en-US")} ${new Date(order.created_at).toLocaleTimeString("en-US")}`}</td> */}
                </tr >
            ))

        ) : (<React.Fragment />)

        let panelBody = {
            overflow: 'auto'
        }

        let panelHead = {
            marginLeft: '3em'
        }

        let panel_one = {
            width: '60%',
            marginLeft: '3em'

        }
        return (
            <React.Fragment>
                <div className="row">

                    <div className="col-xs-6">
                        <NewOrderForm
                            formData={this.state.formData}
                            onSave={this.onSave}
                            onDelete={this.onDelete}
                            onCancel={this.onCancel}
                            customers={this.state.customers}
                            selectedCustomer={this.state.selectedCustomer}
                            selectCustomer={this.onSelectCustomer}
                            pvpId={this.state.pvpId}
                            companyId={this.state.companyId}
                            opId={this.state.opId}
                        />
                    </div>

                    <div className="col-xs-4">
                    <OrdersForm />
                    </div>
                    <div className="col-xs-6">
                        <div id="accordion1">
                            <div className="panel panel-default" style={panel_one}>
                                <div className="panel-heading" id="headingTwo"><h3 className="panel-title"><a role="button" data-toggle="collapse" data-parent="#accordion1" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    Order Items Report
        </a></h3></div>
                                <div id="collapseTwo" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwo">
                                    <div className="panel-body">

                                        {/* <h4>Order Items Report</h4> */}
                                        <form>
                                            <div className="form-group col-md-4">
                                                <label>Start</label>
                                                <input className="form-control" name="date_begin" placeholder="Ex. 2020-10-31" onChange={this.handleChange}></input>


                                            </div>
                                            <div className='form-group col-md-4'>
                                                <label>End</label>
                                                <input className="form-control" name="date_end" placeholder="Ex. 2020-11-04" onChange={this.handleChange}></input>

                                            </div>
                                        </form>
                                    </div>

                                    <div className="panel-footer">

                                        <button type="button" className="btn btn-primary" onClick={() => { this.getOrderItems(this.state.date_begin, this.state.date_end) }}>Download Report</button>
                                        <div className="divider"></div>

                                        <button type="button" className="btn btn-default" onClick={() => { this.getOrderItems(this.state.date_begin, this.state.date_end) }}>Cancel</button>

                                        <CSVLink
                                            data={this.state.order_items}
                                            filename="order_items_report.csv"
                                            className="hidden btn btn-default"
                                            ref={this.csvLink}
                                            target="_blank"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="row justify-content-end">

                    {/* <div className="col-xs-6"><button></button></div> */}
                    <div className="col-xs-2"></div>
                    <div className="col-xs-2"></div>
                    <div className="col-xs-2"></div>
                    <div className="col-xs-2"></div>
                    <div className="col-xs-2"></div>
                    {/* <div className="col-xs-2">
                        <button onClick={this.getOrderStatuses} className="btn btn-primary btn-sm">GET ORDER STATUS</button>
                        <div className="divider"></div>

                    </div> */}
                </div>


                <div className="panel-heading" style={panelHead}>
                    <h3 className="formHead">Orders</h3>
                    <br />
                    <div style={{ display: "flex", flexDirection: "row" }} className="row text-right">
                        <div style={{ marginRight: "1em", marginLeft: "1em" }} className="col-2 text-left">
                            <p className=""><b>Filter By  </b></p>
                        </div>
                        <div className="col-1 text-right">
                            <select name="filterChange" size="1" title="OrderTimeFrame" onChange={this.filterChange} className="custom-select form-select">
                                <option value="ALL">All</option>
                                <option value="SHIPPED">Shipped</option>
                                <option value="DISABLED">Disabled</option>
                                <option value="LATE">Late</option>
                                <option value="REFUNDED">Refunded</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="panel-body" style={panelBody}>

                    <div className="containerr" >
                        <table className="table table-bordered" >
                            <thead>
                                <tr>
                                    <td><strong>ID</strong></td>
                                    <td><strong>Email</strong></td>
                                    <td><strong>OP Order ID</strong></td>
                                    <td><strong>OP Company ID</strong></td>
                                    <td><strong>Company Name</strong></td>
                                    {/* <td><strong>Confirmation</strong></td> */}
                                    <td><strong>PO</strong></td>
                                    <td><strong>Total</strong></td>
                                    <td><strong>Pay Type</strong></td>
                                    <td><strong>Pay Status</strong></td>
                                    <td><strong>Date Ordered</strong></td>
                                    <td><strong>Action</strong></td>
                                    <td><strong>Status</strong></td>
                                    {/* <td><strong>SLA</strong></td> */}
                                    {/* <td><strong>Tracking</strong></td> */}
                                    {/* <td><strong>Created</strong></td> */}

                                </tr>
                            </thead>
                            <tbody>
                                {orders}
                            </tbody>
                        </table>
                        {this.state.showModal && <Modal po={this.state.curr_order} data={this.state.order_json} handleClose={this.hideModal} />}

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Orders;